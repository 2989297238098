import {
  BleClient,
  ScanResult
} from "@capacitor-community/bluetooth-le";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLoading,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { MessageContext } from "../../../../../contexts/MessageContext";
import "./Bluetooth.css";

type Props = {
  closeAction: Function;
  closeActionSimple: Function;
};

const Bluetooth: React.FC<Props> = (props: Props) => {
  const [showLoading, setShowLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [bleDevices, setBleDevices] = useState<any[]>([]);
  const [connectedDevice, setConnectedDevice] = useState<any>(null);
  const { dadosEmpresa, setDadosEmpresa } = useContext(MessageContext);
  const [isScanning, setIsScanning] = useState(false);

  const scanDevices = async () => {
    try {
      setIsScanning(true);
      setBleDevices([]); // Limpa a lista ao iniciar a varredura
      // await BleClient.initialize({ androidNeverForLocation: true });
      await BleClient.initialize();

      console.log("Iniciando varredura...");
      const scanTimeout = setTimeout(() => {
        stopScanning(); // Para a varredura após 10 segundos
      }, 5000); // 10 segundos (10000ms)

      await BleClient.requestLEScan({}, (result: ScanResult) => {
        console.log("Dispositivo encontrado:", result);

        setBleDevices((prevDevices) => {
          if (
            !prevDevices.some(
              (device) => device.deviceId === result.device.deviceId
            )
          ) {
            return [...prevDevices, result.device];
          }
          return prevDevices;
        });
      });

      // Limpar o timeout caso a varredura seja interrompida antes dos 10 segundos
      clearTimeout(scanTimeout);
    } catch (error) {
      console.error("Erro durante a varredura:", error);
    }
  };

  const stopScanning = async () => {
    try {
      if (isScanning) {
        await BleClient.stopLEScan();
        console.log("Varredura interrompida.");
        setIsScanning(false);
      }
    } catch (error) {
      console.error("Erro ao interromper a varredura:", error);
    }
  };

  useEffect(() => {
    scanDevices();
    return () => {
      stopScanning(); // Para a varredura ao desmontar o componente
    };
  }, []);


  const connectToDevice = async (deviceId: string) => {
    try {
      await BleClient.connect(deviceId);
      setConnectedDevice(deviceId);
      console.log("Conectado ao dispositivo:", deviceId);
      props.closeAction(deviceId);

      // Aqui você pode fazer as leituras ou configurações adicionais.
    } catch (error) {
      console.error("Erro ao conectar ao dispositivo:", error);
      setTextToast("Erro ao conectar ao dispositivo");
      setShowToast(true);
    }
  };

  // const printTest = async () => {
  //   if (!connectedDevice) {
  //     setTextToast("Nenhum dispositivo conectado!");
  //     setShowToast(true);
  //     return;
  //   }

  //   try {
  //     const PRINT_SERVICE_UUID = "000018f0-0000-1000-8000-00805f9b34fb";
  //     const PRINT_CHARACTERISTIC_UUID = "00002af1-0000-1000-8000-00805f9b34fb";

  //     var token: string = "";
  //     var tkn = localStorage.getItem("token");
  //     if (typeof (tkn) === "string") { token = tkn; }
  //     setShowLoading(true);
  //     const resp = await fetch(urlBase.url + "/projetos/" + "caixa#2024-12-02T22:11:21.915Z#usuarios#0f824190785611ef9ca7f33fb21e55d2".replaceAll(/#/g, "%23") + "/semassinar",
  //       {
  //         method: urlBase.method,
  //         headers: [
  //           ["token", token]
  //         ],
  //         credentials: 'include'
  //       }).finally(() => {
  //         setShowLoading(false);
  //       });
  //     const response = await resp.json();
  //     if (response.Item !== undefined) {
  //       const comandosEscPos: any = gerarEscPos(response.Item);

  //       // Fragmentar os dados em pacotes menores
  //       const chunkSize = 20; // Tamanho máximo permitido por pacote BLE
  //       const buffer = comandosEscPos.buffer;

  //       const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  //       try {
  //         // Loop para enviar os dados em pacotes
  //         for (let i = 0; i < buffer.byteLength; i += chunkSize) {
  //           const chunk = new DataView(buffer.slice(i, Math.min(i + chunkSize, buffer.byteLength)));
  //           await BleClient.write(
  //             connectedDevice,
  //             PRINT_SERVICE_UUID,
  //             PRINT_CHARACTERISTIC_UUID,
  //             chunk
  //           );
  //           await delay(50); // Atraso de 50ms entre os envios
  //         }

  //         setTextToast("Impressão realizada com sucesso!");
  //         setShowToast(true);
  //       } catch (error) {
  //         console.error("Erro durante a impressão:", error);
  //         setTextToast("Erro ao realizar a impressão.");
  //         setShowToast(true);
  //       }
  //     }

  //   } catch (error) {
  //     console.error("Erro ao imprimir:", error);
  //     setTextToast("Erro ao imprimir");
  //     setShowToast(true);
  //   }
  // };


  // function gerarEscPos(projeto: any) {
  //   // Função para remover acentos e cedilhas
  //   function removerAcentosECedilha(texto: string): string {
  //     return texto
  //       .normalize('NFD')  // Normaliza o texto para decompô-lo em caracteres base e acentos
  //       .replace(/[\u0300-\u036f]/g, '')  // Remove os acentos
  //       .replace(/ç/g, 'c')  // Substitui 'ç' por 'c'
  //       .replace(/Ç/g, 'C');  // Substitui 'Ç' por 'C'
  //   }

  //   // Função para aplicar a máscara de CPF/CNPJ
  //   function aplicarMascaraCPFouCNPJ(texto: any) {
  //     if (!texto) return '';
  //     texto = texto.replace(/\D/g, '');
  //     if (texto.length === 11) {
  //       return texto.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  //     } else if (texto.length === 14) {
  //       return texto.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  //     }
  //     return texto;
  //   }

  //   // Função para formatar telefone
  //   function formataTelefone(numero: any) {
  //     if (numero?.length == 13) {
  //       let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
  //       BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
  //       return BRNumber;
  //     }
  //     else if (numero?.length == 12) {
  //       let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
  //       BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
  //       return BRNumber;
  //     }
  //     else { return "" };
  //   }

  //   function quebrarTextoEmLinhas(texto: any, tamanhoMaximo: any) {
  //     const palavras = texto.split(' ');
  //     let linhas = [];
  //     let linhaAtual = '';

  //     palavras.forEach((palavra: any) => {
  //       if ((linhaAtual + palavra).length <= tamanhoMaximo) {
  //         linhaAtual += palavra + ' ';
  //       } else {
  //         linhas.push(linhaAtual.trim());
  //         linhaAtual = palavra + ' ';
  //       }
  //     });

  //     if (linhaAtual.trim()) {
  //       linhas.push(linhaAtual.trim());
  //     }

  //     return linhas;
  //   }

  //   // Função para formatar valores monetários
  //   const formatarValor = (valor: any) => {
  //     if (!valor) return '0,00';

  //     // Remove pontos de milhares e substitui a vírgula decimal por ponto
  //     const valorNumerico = parseFloat(valor.toString().replace(/\./g, '').replace(',', '.'));

  //     // Formata o número para o padrão brasileiro
  //     return valorNumerico.toFixed(2).replace('.', ',');
  //   };

  //   // Função para gerar o cabeçalho e as informações do cupom
  //   function gerarCabecalho(projeto: any, empresa: any) {
  //     let cabecalhoText = "";

  //     // Informações da empresa
  //     cabecalhoText += `${empresa?.empresaNome}\n`;
  //     cabecalhoText += `CNPJ: ${aplicarMascaraCPFouCNPJ(empresa?.empresaCnpj)}\n`;

  //     // Endereço da empresa
  //     quebrarTextoEmLinhas(`Endereço: ${empresa.empresaEndereco}`, 35).forEach((linha: any) => {
  //       cabecalhoText += `${linha}\n`;
  //     });

  //     cabecalhoText += `Tel: ${formataTelefone(empresa?.empresaTelefone)}\n`;

  //     // Data e hora do cupom, se disponível
  //     if (projeto.dataCupom) {
  //       cabecalhoText += `Data: ${new Date(projeto.dataCupom).toLocaleDateString('pt-BR')} Hora: ${new Date(projeto.dataCupom).toLocaleTimeString('pt-BR')}\n`;
  //     }

  //     cabecalhoText += imprimir58mm ? `********************************\n` : `**************************************\n`;

  //     // Informações do cliente
  //     cabecalhoText += `${projeto.clienteNome}\n`;

  //     // Se o projeto não for interno, exibe informações adicionais do cliente
  //     if (!projeto.interno) {
  //       cabecalhoText += `Tel: ${formataTelefone(projeto.clienteTelefone)}\n`;

  //       // Endereço do cliente, se disponível
  //       quebrarTextoEmLinhas(`Endereço: ${[projeto.endereco, projeto.complemento].filter(Boolean).join(', ') || ""}`, 35).forEach((linha: any) => {
  //         cabecalhoText += `${linha}\n`;
  //       });
  //     }

  //     cabecalhoText += imprimir58mm ? `********************************\n` : `**************************************\n`;
  //     cabecalhoText += "CUPOM NÃO FISCAL\n";

  //     // Se o projeto não for interno, exibe o nome do projeto
  //     if (!projeto.interno) {
  //       cabecalhoText += imprimir58mm ? `================================\n` : `======================================\n`;
  //       cabecalhoText += `${empresa?.servicoTipo || "Projeto"}: ${projeto.nomeProjeto}\n`;
  //       cabecalhoText += imprimir58mm ? `================================\n` : `======================================\n`;
  //     }

  //     return cabecalhoText;
  //   }

  //   // Função para gerar o corpo do cupom (produtos)
  //   function gerarProdutos(projeto: any) {
  //     let produtosText = "";

  //     produtosText += "QTDE | DESCRIÇÃO\n";
  //     produtosText += imprimir58mm ? `--------------------------------\n` : `--------------------------------------\n`;

  //     if (projeto.produtos && Array.isArray(projeto.produtos) && projeto.produtos.length > 0) {
  //       projeto.produtos.forEach((produto: any) => {
  //         // Verifica se o produto possui uma descrição complexa
  //         if (Array.isArray(produto.descricao)) {
  //           produto.descricao.forEach((itemDesc: any, indexLinha: number) => {
  //             // Exibe a linha principal do produto apenas uma vez
  //             if (indexLinha === 0) {
  //               produtosText += `${produto.quantidade} | ${produto.tipo === "pizzas" ? `${produto.tamanho} ${produto.quantidadeSabores} SABORES (R$ ${formatarValor(produto.valor)})` : `${produto.categoria} (R$ ${formatarValor(produto.valor)})`}\n`;
  //             }

  //             // Exibe as descrições adicionais, se houver
  //             if (itemDesc.descricao) {
  //               produtosText += `  - ${itemDesc.descricao} (R$ ${formatarValor(itemDesc.valor)})\n`;
  //             }
  //           });
  //         } else {
  //           // Para produtos com uma única descrição simples
  //           produtosText += `${produto.quantidade} | ${produto.descricao} (R$ ${formatarValor(produto.valor)})\n`;
  //         }

  //         // Adiciona o subtotal de cada produto
  //         produtosText += `VALOR: R$ ${formatarValor(produto.subtotal)}\n`;
  //         produtosText += imprimir58mm ? `--------------------------------\n` : `--------------------------------------\n`;
  //       });

  //       // Adiciona o total de todos os produtos
  //       if (projeto.valorTotalProdutos) {
  //         produtosText += `TOTAL: R$ ${formatarValor(projeto.valorTotalProdutos)}\n`;
  //       }
  //     }

  //     return produtosText;
  //   }

  //   // Função para gerar o footer com as informações de pagamento
  //   function gerarFooter(projeto: any, empresa: any) {
  //     let pagamentoText = "";

  //     // Forma de pagamento
  //     if (projeto.formaPagamento) {
  //       if (!Array.isArray(projeto.formaPagamento)) {
  //         pagamentoText += imprimir58mm ? `********************************\n` : `**************************************\n`;
  //         pagamentoText += `FORMA PAGAMENTO: ${projeto.formaPagamento?.toUpperCase()}\n`;
  //       }
  //     }

  //     // Troco, se houver
  //     if (projeto.troco && projeto.troco !== "0,00") {
  //       pagamentoText += `TROCO: R$ ${formatarValor(projeto.troco)}\n`;
  //     }

  //     // Taxa de entrega, se houver
  //     if (projeto.taxaEntrega) {
  //       pagamentoText += `TAXA DE ENTREGA: +R$ ${formatarValor(projeto.taxaEntrega)}\n`;
  //     }

  //     // Desconto, se houver
  //     if (projeto.desconto) {
  //       pagamentoText += `DESCONTO: -R$ ${formatarValor(projeto.desconto)}\n`;
  //     }

  //     // Valor total, se houver
  //     if (projeto.valorTotal) {
  //       pagamentoText += imprimir58mm ? `********************************\n` : `**************************************\n`;
  //       pagamentoText += `TOTAL ${empresa.servicoTipo?.toUpperCase() || "PROJETO"}: R$ ${formatarValor(projeto.valorTotal)}\n`;
  //     }

  //     return pagamentoText;
  //   }

  //   try {


  //     console.log("dadosImpressao1");
  //     // Montando o cupom com os dados no formato ESC/POS
  //     const cabecalho = gerarCabecalho(projeto, dadosEmpresa);

  //     console.log("cabecalho", cabecalho);
  //     const produtos = gerarProdutos(projeto);
  //     console.log("produtos", produtos);
  //     const rodape = gerarFooter(projeto, dadosEmpresa);
  //     console.log("rodape", rodape);
  //     // Comandos ESC/POS básicos

  //     const ESC = 0x1B;  // ESC
  //     const LF = 0x0A;   // Line Feed
  //     const CORTA_PAPEL = [ESC, 0x69];   // Corta Papel
  //     const NEGRITO_ON = [ESC, 0x45, 0x01];  // Ativar negrito
  //     const NEGRITO_OFF = [ESC, 0x45, 0x00]; // Desativar negrito
  //     const ALIMENTAR_PAPEL = [ESC, 0x64, 0x10]; // Alimentação de papel


  //     const dadosImpressao: any = [
  //       // ...ALIMENTAR_PAPEL, // Alimentação de papel
  //       LF,
  //       ...NEGRITO_ON,
  //       ...removerAcentosECedilha(cabecalho).split("").map(c => c.charCodeAt(0)),
  //       ...NEGRITO_OFF, // Desativar negrito
  //       LF, // Pular linha
  //       ...removerAcentosECedilha(produtos).split("").map(c => c.charCodeAt(0)),
  //       LF, // Pular linha
  //       ...removerAcentosECedilha(rodape).split("").map(c => c.charCodeAt(0)),
  //       LF, LF, LF, LF, LF,
  //       // ...ALIMENTAR_PAPEL, // Alimentação de papel
  //       ...CORTA_PAPEL

  //     ];

  //     // Retorna os dados como Uint8Array
  //     return new Uint8Array(dadosImpressao);
  //   } catch (error) {
  //     console.error('Erro ao imprimir cupom:', error);
  //     return null; // Retorna null em caso de erro
  //   }
  // }

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonIcon
              slot="icon-only"
              icon={arrowBack}
              onClick={() => {
                props.closeActionSimple();
              }}
            />
          </IonButtons>
          <IonTitle>Bluetooth</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={false}>
        <IonLoading isOpen={showLoading} message="Carregando..." />
        <IonToast isOpen={showToast} message={textToast} duration={3000} />
        {/* <IonButton onClick={printTest} color="secondary">
          Imprimir Teste
        </IonButton> */}
        {bleDevices.length > 0 && (
          <div>
            <h3>Dispositivos Encontrados:</h3>
            <ul>
              {bleDevices
                .filter(device => device.name) // Filtra apenas os dispositivos com um nome definido
                .map((device, index) => (
                  <div key={index}>
                    <li>
                      {device.name} <br />
                      {device.deviceId}
                    </li>
                    <IonButton onClick={() => connectToDevice(device.deviceId)}>
                      Conectar
                    </IonButton>
                  </div>
                ))}
            </ul>
          </div>
        )}


      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonButton onClick={() => scanDevices()} expand="block" type="submit">Escanear Dispositivos</IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default Bluetooth;
