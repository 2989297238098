import { BleClient } from '@capacitor-community/bluetooth-le';
import { IonButton, IonButtons, IonCard, IonCardContent, IonChip, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonPopover, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar, isPlatform, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import { Buffer } from 'buffer';
import { addCircle, arrowBack, checkmarkCircle, closeOutline, ellipsisVertical, pricetagsOutline, removeCircle, trash } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { empresaAlterar, empresaCarregar, incluirProjeto, listaEquipePorPerfil, projetoGerarCupom, removerTagsEnderecoEmpresa, requestService, urlBase } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import Endereco from '../Endereco/Endereco';
import ProjetosProdutosComporNovoMemory from '../ProjetosProdutosComporNovoMemory/ProjetosProdutosComporNovoMemory';
import './ProjetosNovoPedidoVenda.css';

type Props = {
  closeAction: Function;
  closeActionSimple: Function;
}

const ProjetosNovoPedidoVenda: React.FC<Props> = (props: Props) => {
  // Navigation
  const history = useHistory();
  const location = useLocation<any>();

  // Alert and Toast
  const [mostartAlerta] = useIonAlert();
  const [textToast, setTextToast] = useState("");
  const [showToast, setShowToast] = useState(false);

  // Loading State
  const [showLoading, setShowLoading] = useState(false);

  // Contexts
  const { loja, setLoja } = useContext(MessageContext);
  const { dadosEmpresa, setDadosEmpresa } = useContext(MessageContext);
  // const [dadosEmpresa, setDadosEmpresa] = useState<any>([]);
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);
  const { pkUsuario, setPkUsuario } = useContext(MessageContext);

  // State: Loja
  const [lojas, setLojas] = useState<string>(loja);

  // State: Filtro e Listagem
  const [textoFiltro, setTextoFiltro] = useState<any>();
  const [listaItemProdutos, setListaItemProdutos] = useState<any>([]);

  // State: Detalhes do Endereço
  const [showDetalhesEndereco, setShowDetalhesEndereco] = useState(false);
  const inputRefEndereco = useRef<any>(null);
  const [endereco, setEndereco] = useState("");
  const [enderecoLat, setEnderecoLat] = useState("");
  const [enderecoLng, setEnderecoLng] = useState("");
  const [complemento, setComplemento] = useState("");

  // State: Cliente
  const [pkCliente, setPkCliente] = useState("");
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");

  // State: Valores e Pagamento
  const [troco, setTroco] = useState<any>();
  const [valorTotal, setValorTotal] = useState<any>();
  const [valorTotalProdutos, setValorTotalProdutos] = useState<any>();
  const [formaPagamento, setFormaPagamento] = useState('');
  const [taxaEntrega, setTaxaEntrega] = useState<any>();
  const [desconto, setDesconto] = useState<any>();
  const [taxaServico, setTaxaServico] = useState<any>();

  // Refs
  const campoTelefone = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const contentCatalogoRef = useRef<any>(null);

  // Dados da Empresa
  // const [dadosEmpresa, setDadosEmpresa] = useState<any>([]);

  const [desabilitarInfiniteScroll, setDesabilitarInfiniteScroll] = useState(false);
  const [projetopk, setProjetopk] = useState("");
  const [projetosk, setProjetosk] = useState("");
  const [projetogsi2sk, setProjetogsi2sk] = useState("");
  const [arrayProjetos, setArrayProjetos] = useState<any>([]);


  // utilizado para ver o historico de pedidos
  const [filtroCampoSelecionadoProjeto, setFiltroCampoSelecionadoProjeto] = useState<any>("nome");
  const [filtroStatusProjeto, setFiltroStatusProjeto] = useState<any>("Encerrado");

  const [showModalHistorico, setShowModalHistorico] = useState(false);

  const inputRefTag = useRef<any>(null);
  const [showModalTags, setShowModalTags] = useState(false);
  const [showModalTagsAdd, setShowModalTagsAdd] = useState(false);
  const [listaTagsEndereco, setListaTagsEndereco] = useState<any>();
  const [nomeTag, setNomeTag] = useState("");

  // Estado para armazenar valor persistido no localStorage
  const [impressoraBluetoothHabilitar, setImpressoraBluetoothHabilitar] = useState<boolean>(() => {
    const storedValue = window.localStorage.getItem("impressoraBluetoothHabilitar");
    return storedValue === "true";
  });

  const [impressoraLocalHabilitar, setImpressoraLocalHabilitar] = useState<boolean>(() => {
    const storedValue = window.localStorage.getItem("impressoraLocalHabilitar");
    return storedValue === "true";
  });

  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState(null);

  const openPopover = (e: any) => {
    e.persist();
    setPopoverEvent(e);
    setShowPopover(true);
  };

  const closePopover = () => {
    setShowPopover(false);
    setPopoverEvent(null);
  };


  const opcoesPagamento = [
    'Dinheiro',
    'Cartão de Crédito',
    'Cartão de Débito',
    'Pix',
    'Alelo',
    'Sodexo',
    'Vale Alimentação',
    'Boleto'
  ];

  const opcoesTaxaEntrega = [
    { label: 'Grátis', value: '0,00' },
    { label: 'R$ 5,00', value: '5,00' },
    { label: 'R$ 6,00', value: '6,00' },
    { label: 'R$ 7,00', value: '7,00' },
    { label: 'R$ 8,00', value: '8,00' },
    { label: 'R$ 10,00', value: '10,00' },
    { label: 'R$ 12,00', value: '12,00' },
    { label: 'R$ 15,00', value: '15,00' },
  ];

  const opcoesDesconto = [
    { label: 'R$ 0,00', value: '0,00' },
    { label: 'R$ 5,00', value: '5,00' },
    { label: 'R$ 10,00', value: '10,00' },
    { label: 'R$ 15,00', value: '15,00' },
    { label: 'R$ 20,00', value: '20,00' },
    { label: 'R$ 25,00', value: '25,00' }
  ];

  useEffect(() => {

    try {
      let taxaServicoFloat = parseFloat(taxaServico?.replace(/\./g, '').replace(/\,/g, '.'));
      let descontoFloat = parseFloat(desconto?.replace(/\./g, '').replace(/\,/g, '.'));
      let taxaEntregaFloat = parseFloat(taxaEntrega?.replace(/\./g, '').replace(/\,/g, '.'));

      let valorTotalRecalculado = 0;
      if (valorTotalProdutos) {
        valorTotalRecalculado = parseFloat(valorTotalProdutos.replace(/\./g, '').replace(/\,/g, '.'));
      }

      if (taxaEntrega) {
        valorTotalRecalculado += (taxaEntregaFloat ? taxaEntregaFloat : 0);
      }

      if (desconto) {
        valorTotalRecalculado += - (descontoFloat ? descontoFloat : 0);
      }
      if (taxaServico) {
        valorTotalRecalculado += (valorTotalRecalculado * (taxaServicoFloat / 100));
      }

      setValorTotal(valorTotalRecalculado.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));

    } catch (error) {
      console.log("erro ao calcular", error);
    }
  }, [taxaEntrega, taxaServico, desconto, valorTotalProdutos]);

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    carregarEmpresa();

    if (inputRef.current) {
      inputRef.current.setFocus();
    }

    setPkCliente("");
    setTextoFiltro("");

  })

  function carregarEmpresa() {

    const consultar = async () => {

      requestService(empresaCarregar.url.replace("{id}", loja.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
        method: empresaCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {

          setDadosEmpresa(response.Item);
          setListaTagsEndereco(response.Item.tagsEndereco || []);

          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }

  const loadTagsEndereco = () => {

    console.log();
    if (listaTagsEndereco == undefined) return;

    if (listaTagsEndereco.length === 0) {
      return (

        <IonItem lines="full" key={"semAgenda"}>

          <IonLabel>
            <h2 style={{ "verticalAlign": "middle" }}><strong>Nenhuma tag cadastrada</strong></h2>
          </IonLabel>

        </IonItem>


      );
    }

    return listaTagsEndereco.map((value: any, index: any) => (
      <IonItem lines="full" key={index}>
        {/* <IonIcon icon={add} slot="start" color="success"> </IonIcon> */}
        <IonLabel>
          {value}
        </IonLabel>
        <IonIcon slot="end" color='primary' icon={trash} onClick={() => { deletarTag(value) }} />



      </IonItem>
    ));
  }

  function deletarTag(nome: any) {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const del = async () => {
      const resp = await fetch(removerTagsEnderecoEmpresa.url.replace("{id}", loja.replace(/#/g, "%23")),
        {
          method: removerTagsEnderecoEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'nome': nome }),
          credentials: 'include'
        });
      const response = await resp.json();
      console.log("retorno", response);

      if (response) {
        setTextToast("Tag removida com sucesso.");
        setShowToast(true);

        setDadosEmpresa(response.message.Attributes);
        setListaTagsEndereco(response.message.Attributes.tagsEndereco || []);
      }

    }

    del();

  }

  function gerarEscPos(projeto: any, imprimir58mm: any = false) {
    // Função para remover acentos e cedilhas
    function removerAcentosECedilha(texto: string): string {
      return texto
        .normalize('NFD')  // Normaliza o texto para decompô-lo em caracteres base e acentos
        .replace(/[\u0300-\u036f]/g, '')  // Remove os acentos
        .replace(/ç/g, 'c')  // Substitui 'ç' por 'c'
        .replace(/Ç/g, 'C');  // Substitui 'Ç' por 'C'
    }

    // Função para aplicar a máscara de CPF/CNPJ
    function aplicarMascaraCPFouCNPJ(texto: any) {
      if (!texto) return '';
      texto = texto.replace(/\D/g, '');
      if (texto.length === 11) {
        return texto.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      } else if (texto.length === 14) {
        return texto.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      }
      return texto;
    }

    // Função para formatar telefone
    function formataTelefone(numero: any) {
      if (numero?.length == 13) {
        let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
        BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
        return BRNumber;
      }
      else if (numero?.length == 12) {
        let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
        BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
        return BRNumber;
      }
      else { return "" };
    }

    function quebrarTextoEmLinhas(texto: any, tamanhoMaximo: any) {
      const palavras = texto.split(' ');
      let linhas = [];
      let linhaAtual = '';

      palavras.forEach((palavra: any) => {
        if ((linhaAtual + palavra).length <= tamanhoMaximo) {
          linhaAtual += palavra + ' ';
        } else {
          linhas.push(linhaAtual.trim());
          linhaAtual = palavra + ' ';
        }
      });

      if (linhaAtual.trim()) {
        linhas.push(linhaAtual.trim());
      }

      return linhas;
    }

    // Função para formatar valores monetários
    const formatarValor = (valor: any) => {
      if (!valor) return '0,00';

      // Remove pontos de milhares e substitui a vírgula decimal por ponto
      const valorNumerico = parseFloat(valor.toString().replace(/\./g, '').replace(',', '.'));

      // Formata o número para o padrão brasileiro
      return valorNumerico.toFixed(2).replace('.', ',');
    };

    // Função para gerar o cabeçalho e as informações do cupom
    function gerarCabecalho(projeto: any, empresa: any) {

      let cabecalhoText = "";

      // Informações da empresa
      cabecalhoText += `${empresa?.empresaNome}\n`;
      cabecalhoText += `CNPJ: ${aplicarMascaraCPFouCNPJ(empresa?.empresaCnpj)}\n`;

      // Endereço da empresa
      quebrarTextoEmLinhas(`Endereço: ${empresa.empresaEndereco}`, 35).forEach((linha: any) => {
        cabecalhoText += `${linha}\n`;
      });

      cabecalhoText += `Tel: ${formataTelefone(empresa?.empresaTelefone)}\n`;

      // Data e hora do cupom, se disponível
      if (projeto.dataCupom) {
        cabecalhoText += `Data: ${new Date(projeto.dataCupom).toLocaleDateString('pt-BR')} Hora: ${new Date(projeto.dataCupom).toLocaleTimeString('pt-BR')}\n`;
      }

      cabecalhoText += imprimir58mm ? `********************************\n` : `**************************************\n`;

      // Informações do cliente
      cabecalhoText += `${projeto.cliente.nome}\n`;

      // Se o projeto não for interno, exibe informações adicionais do cliente
      if (!projeto.interno) {
        cabecalhoText += `Tel: ${formataTelefone(projeto.cliente?.telefone)}\n`;

        // Endereço do cliente, se disponível
        quebrarTextoEmLinhas(`Endereço: ${[projeto.cliente?.endereco, projeto.cliente?.complemento].filter(Boolean).join(', ') || ""}`, 35).forEach((linha: any) => {
          cabecalhoText += `${linha}\n`;
        });
      }

      cabecalhoText += imprimir58mm ? `********************************\n` : `**************************************\n`;
      cabecalhoText += "CUPOM NÃO FISCAL\n";

      // Se o projeto não for interno, exibe o nome do projeto
      if (!projeto.interno) {
        cabecalhoText += imprimir58mm ? `================================\n` : `======================================\n`;
        cabecalhoText += `${empresa?.servicoTipo || "Projeto"}: ${projeto.nomeProjeto}\n`;
        cabecalhoText += imprimir58mm ? `================================\n` : `======================================\n`;
      }

      return cabecalhoText;
    }

    // Função para gerar o corpo do cupom (produtos)
    function gerarProdutos(projeto: any) {
      let produtosText = "";

      produtosText += "QTDE | DESCRIÇÃO\n";
      produtosText += imprimir58mm ? `--------------------------------\n` : `--------------------------------------\n`;

      if (projeto.produtos && Array.isArray(projeto.produtos) && projeto.produtos.length > 0) {
        projeto.produtos.forEach((produto: any) => {
          // Verifica se o produto possui uma descrição complexa
          if (Array.isArray(produto.descricao)) {
            produto.descricao.forEach((itemDesc: any, indexLinha: number) => {
              // Exibe a linha principal do produto apenas uma vez
              if (indexLinha === 0) {
                produtosText += `${produto.quantidade} | ${produto.tipo === "pizzas" ? `${produto.tamanho} ${produto.quantidadeSabores} SABORES (R$ ${formatarValor(produto.valor)})` : `${produto.categoria} (R$ ${formatarValor(produto.valor)})`}\n`;
              }

              // Exibe as descrições adicionais, se houver
              if (itemDesc.descricao) {
                produtosText += `  - ${itemDesc.descricao} (R$ ${formatarValor(itemDesc.valor)})\n`;
              }
            });
          } else {
            // Para produtos com uma única descrição simples
            produtosText += `${produto.quantidade} | ${produto.descricao} (R$ ${formatarValor(produto.valor)})\n`;
          }

          // Adiciona o subtotal de cada produto
          produtosText += `VALOR: R$ ${formatarValor(produto.subtotal)}\n`;
          produtosText += imprimir58mm ? `--------------------------------\n` : `--------------------------------------\n`;
        });

        // Adiciona o total de todos os produtos
        if (projeto.valorTotalProdutos) {
          produtosText += `TOTAL: R$ ${formatarValor(projeto.valorTotalProdutos)}\n`;
        }
      }

      return produtosText;
    }

    // Função para gerar o footer com as informações de pagamento
    function gerarFooter(projeto: any, empresa: any) {
      let pagamentoText = "";

      if (projeto.pedidoIfood) {
        pagamentoText += imprimir58mm ? `*************iFood**************\n` : `****************iFood*****************\n`;

        if (projeto.taxas) {
          pagamentoText += `TAXAS: +R$ ${formatarValor(projeto.taxas)}\n`;
        }

        if (projeto.localizadorIfood) {
          pagamentoText += `LOCALIZADOR IFOOD: ${projeto.localizadorIfood}\n`;
        }

        if (projeto.tipoEntrega) {
          pagamentoText += `TIPO DE ENTREGA: ${projeto.tipoEntrega}\n`;
        }

        if (projeto.horarioEntrega) {
          pagamentoText += `HORÁRIO DE ENTREGA: ${projeto.horarioEntrega}\n`;
        }

        if (projeto.extraInfo) {
          pagamentoText += `INFORMAÇÕES EXTRAS: ${projeto.extraInfo}\n`;
        }
      }

      // Forma de pagamento
      if (projeto.formaPagamento) {
        if (!Array.isArray(projeto.formaPagamento)) {
          pagamentoText += imprimir58mm ? `********************************\n` : `**************************************\n`;
          pagamentoText += `FORMA PAGAMENTO: ${projeto.formaPagamento?.toUpperCase()}\n`;
        }
      }

      // Troco, se houver
      if (projeto.troco && projeto.troco !== "0,00") {
        pagamentoText += `TROCO: R$ ${formatarValor(projeto.troco)}\n`;
      }

      // Taxa de entrega, se houver
      if (projeto.taxaEntrega) {
        pagamentoText += `TAXA DE ENTREGA: +R$ ${formatarValor(projeto.taxaEntrega)}\n`;
      }

      // Desconto, se houver
      if (projeto.desconto) {
        pagamentoText += `DESCONTO: -R$ ${formatarValor(projeto.desconto)}\n`;
      }

      // Valor total, se houver
      if (projeto.valorTotal) {
        pagamentoText += imprimir58mm ? `********************************\n` : `**************************************\n`;
        pagamentoText += `TOTAL ${empresa.servicoTipo?.toUpperCase() || "PROJETO"}: R$ ${formatarValor(projeto.valorTotal)}\n`;
      }

      return pagamentoText;
    }

    function gerarQrCode(pk: string): string {
      let qrCodeText = "";

      // Comando de inicialização do QR Code
      qrCodeText += "\x1D\x28\x6B"; // Comando inicial
      qrCodeText += "\x04\x00\x31\x41\x32\x00"; // Configuração do modelo do QR (modelo 2)

      qrCodeText += "\x1D\x28\x6B"; // Tamanho dos módulos
      qrCodeText += "\x03\x00\x31\x43\x07"; // Define tamanho como 7

      // Comando para armazenar os dados
      qrCodeText += "\x1D\x28\x6B" +
        String.fromCharCode(pk.length + 3) + "\x00\x31\x50\x30" + pk;

      // Comando para imprimir o QR Code
      qrCodeText += "\x1D\x28\x6B\x03\x00\x31\x51\x30";

      return qrCodeText;
    }

    try {


      console.log("dadosImpressao1");
      // Montando o cupom com os dados no formato ESC/POS
      const cabecalho = gerarCabecalho(projeto, dadosEmpresa);

      console.log("cabecalho", cabecalho);
      const produtos = gerarProdutos(projeto);
      console.log("produtos", produtos);
      const rodape = gerarFooter(projeto, dadosEmpresa);
      console.log("rodape", rodape);
      const qrCodeGerado = gerarQrCode(projeto.pk);
      console.log("qrCodeGerado", qrCodeGerado);
      // Comandos ESC/POS básicos

      const ESC = 0x1B;  // ESC
      const LF = 0x0A;   // Line Feed
      const CORTA_PAPEL = [ESC, 0x69];   // Corta Papel
      const NEGRITO_ON = [ESC, 0x45, 0x01];  // Ativar negrito
      const NEGRITO_OFF = [ESC, 0x45, 0x00]; // Desativar negrito
      const ALIMENTAR_PAPEL = [ESC, 0x64, 0x10]; // Alimentação de papel


      const dadosImpressao: any = [
        // ...ALIMENTAR_PAPEL, // Alimentação de papel
        LF,
        ...NEGRITO_ON,
        ...removerAcentosECedilha(cabecalho).split("").map(c => c.charCodeAt(0)),
        ...NEGRITO_OFF, // Desativar negrito
        LF, // Pular linha
        ...removerAcentosECedilha(produtos).split("").map(c => c.charCodeAt(0)),
        LF, // Pular linha
        ...removerAcentosECedilha(rodape).split("").map(c => c.charCodeAt(0)),
        LF, // Pular linha
        ...qrCodeGerado.split("").map(c => c.charCodeAt(0)),
        LF, LF, LF, LF, LF,
        // ...ALIMENTAR_PAPEL, // Alimentação de papel
        ...CORTA_PAPEL

      ];

      // Retorna os dados como Uint8Array
      return new Uint8Array(dadosImpressao);
    } catch (error) {
      console.error('Erro ao imprimir cupom:', error);
      return null; // Retorna null em caso de erro
    }
  }

  function validarCampos() {

    if (nome.length == 0) {
      setTextToast("Campo Nome é obrigatório.");
      setShowToast(true);
      return false;
    }
    else if (telefone.length != 15 && telefone.length != 14) {
      setTextToast("Campo Telefone é obrigatório.");
      setShowToast(true);
      return false;
    }
    else if (endereco.length == 0) {
      setTextToast("Campo Endereço é obrigatório.");
      setShowToast(true);
      return false;
    }
    else if (listaItemProdutos.length == 0) {
      setTextToast("Adicione pelo menos um item.");
      setShowToast(true);
      return false;
    }
    else if (!formaPagamento) {
      setTextToast("Campo Forma de pagamento é obrigatório.");
      setShowToast(true);
      return false;
    }
    else if (!taxaEntrega) {
      setTextToast("Campo Taxa de entrega é obrigatório.");
      setShowToast(true);
      return false;
    }


    return true;
  }

  function avancar(pularEndereco: boolean = false) {

    if (!validarCampos())
      return;

    setShowLoading(true);

    let objRequest = {
      "nomeCliente": nome,
      "telefoneCliente": "55" + telefone.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""),
      lojas: JSON.parse(window.localStorage.getItem("lojas") || "[]").filter((value: any) => {
        return lojas.includes(value.empresaLojaId);
      }),
      "pularEndereco": pularEndereco,
      "endereco": endereco || "",
      "complemento": complemento || "",
      "enderecoLat": enderecoLat,
      "enderecoLng": enderecoLng,
      "clienteid": pkCliente.replaceAll(/#/g, '%23'),
      "responsavelid": "",
      "empresaid": loja || "",
      "responsaveis": [pkUsuario.replaceAll(/#/g, '%23')],
      "interno": false,
      "produtos": listaItemProdutos,
      "formaPagamento": formaPagamento,
      "troco": troco,
      "taxaEntrega": taxaEntrega,
      "desconto": desconto,
      "taxaServico": taxaServico

    };

    requestService(incluirProjeto.url, {
      method: incluirProjeto.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      body: JSON.stringify(objRequest),
      credentials: 'include'
    },
      history,
      async (response: any) => {




        if (response.message && response.message.pk) {

          // atualiza estoque
          // if (response.data) {
          //   setDadosEmpresa(response.data)
          // }
          // console.log("response", response);

          // gerarEscPos(response.message.vo);
          // return;

          if (dadosEmpresa.impressoraRedeHabilitar && (!isPlatform('mobileweb') && isPlatform('mobile'))) {
            const ipImpressora = dadosEmpresa.impressoraRedeIp;
            const portaImpressora = dadosEmpresa.impressoraRedePorta;

            const conectarEEnviar = () => {
              // Cria o socket TCP
              window.chrome.sockets.tcp.create({}, (createInfo) => {
                const socketId = createInfo.socketId;

                // Define o timeout para mostrar a mensagem de erro após 3 segundos
                const timeoutId = setTimeout(() => {
                  setTextToast("Erro ao conectar.");
                  setShowToast(true);
                  window.chrome.sockets.tcp.close(socketId);
                }, 3000); // 3 segundos

                // Tenta conectar ao IP e porta especificados
                window.chrome.sockets.tcp.connect(socketId, ipImpressora, portaImpressora, (result) => {
                  if (result === 0) {
                    // Conexão bem-sucedida, cancela o timeout
                    clearTimeout(timeoutId);

                    console.log('Conexão bem-sucedida! Enviando dados...');

                    const comandosEscPos = gerarEscPos(response.message.vo, (dadosEmpresa.impressoraLargura == "58" ? true : false));
                    if (comandosEscPos) {
                      window.chrome.sockets.tcp.send(socketId, comandosEscPos.buffer, (sendInfo) => {
                        console.log('Comando enviado com sucesso:', sendInfo);
                        window.chrome.sockets.tcp.close(socketId);
                      });
                    } else {
                      console.error('Erro ao gerar ESC/POS');
                    }
                  } else {
                    // Conexão falhou, cancela o timeout e mostra mensagem de erro
                    clearTimeout(timeoutId);
                    console.error(`Erro ao conectar à impressora. Verifique a conexão.`);
                    setTextToast("Erro ao conectar.");
                    setShowToast(true);
                    window.chrome.sockets.tcp.close(socketId);
                  }
                });
              });
            };


            // Chame a função com o URL do HTML que você deseja imprimir
            conectarEEnviar();
          }
          else if (impressoraLocalHabilitar && !(!isPlatform('mobileweb') && isPlatform('mobile'))) {

            console.log("*********************impressao browser pela rede");
            const ipImpressora = dadosEmpresa.impressoraRedeIp;
            const portaImpressora = dadosEmpresa.impressoraRedePorta;



            const conectarEEnviar = () => {
              // Cria o socket TCP


              console.log('Conexão bem-sucedida! Enviando dados...');

              const comandosEscPos: any = gerarEscPos(response.message.vo, (dadosEmpresa.impressoraLargura == "58" ? true : false));

              if (comandosEscPos) {
                // Exemplo de uso
                const resultadoString = converterParaString(comandosEscPos);
                // const resultadoString = arrayToEscapedString(comandosEscPos);
                //console.log(resultadoString);




                const printerName = window.localStorage.getItem("impressoraLocalName") || "";

                enviarImpressao(printerName, resultadoString);




              } else {
                console.error('Erro ao gerar ESC/POS');
              }

            };


            // Chame a função com o URL do HTML que você deseja imprimir
            conectarEEnviar();


          }
          else if (impressoraBluetoothHabilitar && (!isPlatform('mobileweb') && isPlatform('mobile'))) {
            const comandosEscPos = gerarEscPos(response.message.vo, (dadosEmpresa.impressoraLargura == "58" ? true : false));
            if (comandosEscPos) {
              const PRINT_SERVICE_UUID = "000018f0-0000-1000-8000-00805f9b34fb";
              const PRINT_CHARACTERISTIC_UUID = "00002af1-0000-1000-8000-00805f9b34fb";
              // Fragmentar os dados em pacotes menores
              const chunkSize = 20; // Tamanho máximo permitido por pacote BLE
              const buffer = comandosEscPos.buffer;

              const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

              try {
                // Loop para enviar os dados em pacotes
                for (let i = 0; i < buffer.byteLength; i += chunkSize) {
                  const chunk = new DataView(buffer.slice(i, Math.min(i + chunkSize, buffer.byteLength)));
                  await BleClient.write(
                    window.localStorage.getItem("impressoraBluetoothDeviceId") || "",
                    PRINT_SERVICE_UUID,
                    PRINT_CHARACTERISTIC_UUID,
                    chunk
                  );
                  await delay(50); // Atraso de 50ms entre os envios
                }

                setTextToast("Impressão realizada com sucesso!");
                setShowToast(true);

              } catch (error) {
                console.error("Erro durante a impressão:", error);
                setTextToast("Erro ao realizar a impressão.");
                setShowToast(true);
              }
            } else {
              console.error('Erro ao gerar ESC/POS');
            }
          }
          else {
            gerarCupom(response.message.pk);
          }
          history.goBack();
          // }
          // else {




          //   mostartAlerta({
          //     backdropDismiss: false,
          //     header: 'Cadastro realizado com sucesso',
          //     message: 'Deseja imprimir?',

          //     buttons: [{
          //       text: 'Cancelar',
          //       role: 'cancel',
          //       handler: () => {
          //         history.goBack();
          //       }
          //     },
          //     {
          //       text: 'Confirmar',
          //       handler: async () => {

          //         await openNewTabWithHTML(response.message.vo);
          //         history.goBack();
          //       }
          //     }],
          //     onDidDismiss: () => { console.log('did dismiss'); }
          //   })

          // }




        }

      },
      (error: any) => {
        console.log(error);




        mostartAlerta({
          backdropDismiss: false,
          header: 'Endereço com problema',
          message: error.message,

          buttons: [{
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Confirmar',
            handler: () => {
              avancar(true);
            }
          }],
          onDidDismiss: () => { console.log('did dismiss'); }
        })
      }).finally(() => {
        setShowLoading(false);
      });




  }


  function converterParaString(dadosImpressao: any) {
    return String.fromCharCode(...dadosImpressao.filter((byte: any) => byte <= 0xFF)); // Filtrando valores válidos
  }

  async function enviarImpressao(printer: any, data: any) {

    const url = "http://localhost:9100/print";

    // Montando o corpo da requisição no formato necessário
    var raw = JSON.stringify({
      "printer": printer,
      "data": data,  // Dados no formato correto (com códigos ESC/POS)
    });

    try {
      // Configurações da requisição
      const requestOptions: any = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: 'follow',
      };

      // Envia a requisição utilizando fetch
      const resp = await fetch(url, requestOptions);

      // Aguardar a resposta como texto
      const result = await resp.text();

      if (result) {
        console.log("Impressão enviada com sucesso:", result);
      } else {
        console.error("Erro: Nenhuma resposta recebida.");
      }
    } catch (error) {
      console.error("Erro ao enviar dados para a impressora:", error);
    }
  }

  async function gerarCupom(projetoid: any) {


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    setShowLoading(true);
    const resp = await fetch(projetoGerarCupom.url.replaceAll("{id}", projetoid.replaceAll(/#/g, "%23")),
      {
        method: projetoGerarCupom.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    const result = await resp.json();
    if (result) {

      mostartAlerta({
        backdropDismiss: false,
        header: 'Gerar Cupom',
        message: '<strong>Cupom</strong> foi gerado com sucesso.',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Visualizar',
            cssClass: 'primary',
            handler: () => {
              window.open(result, '_blank');
            }
          }
        ],
        onDidDismiss: (e) => console.log('did dismiss'),
      })

    }

    setShowLoading(false);

  }

  const handleSelectItem = (item: any) => {

    setNome(item.nome);
    setTelefone(formataTelefone(item.telefone));
    setEndereco(item.endereco);
    setEnderecoLat(item.enderecoLat);
    setEnderecoLng(item.enderecoLng);
    setComplemento(item.complemento);
    setPkCliente(item.pk);
  };

  const buscarCliente = async (clienteTelefone: any) => {

    if (clienteTelefone && (clienteTelefone.length == 11 || clienteTelefone.length == 10)) {
      let listaClientes = await buscarClienteDynamoDb(clienteTelefone);

      if (Array.isArray(listaClientes) && listaClientes.length > 0) {
        handleSelectItem(listaClientes[0]);
      }
      else {
        mostartAlerta({
          backdropDismiss: false,
          header: 'Cliente não cadastrado',
          message: 'O cliente não está cadastrado. Deseja criar um novo cadastro?',

          buttons: [{
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Confirmar', handler: () => {
              setTelefone(formataTelefone("55" + textoFiltro));
              setNome("");
              setEndereco("");
              setEnderecoLat("");
              setEnderecoLng("");
              setComplemento("");
              setPkCliente("");
            }
          }],
          onDidDismiss: () => { console.log('did dismiss'); }
        })

      }
    }

  }

  const buscarClienteDynamoDb = async (clienteTelefone: any) => {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    // const filtro = "?status=ativo#55" + clienteTelefone
    const filtro = "?telefone=telefone#55" + clienteTelefone
    let montarUrl = listaEquipePorPerfil.url.replace("{loja}", loja).concat(filtro).replaceAll(/#/g, "%23").concat("&lojas=" + Buffer.from(loja).toString('base64'));

    const resp = await fetch(montarUrl.replace("{perfil}", "cliente"),
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    let equipe = await resp.json();
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      if (equipe.Items) {
        return equipe.Items;
      }
    }
  }

  function formataTelefone(numero: any) {
    if (numero?.length == 13) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else if (numero?.length == 12) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else { return "" };
  }

  async function closeModalEndereco(enderecoSelecionado: any, latitudeSelecionado: any, enderecoLngSelecionado: any) {
    console.log("enderecoSelecionado", enderecoSelecionado);
    console.log("latitudeSelecionado", latitudeSelecionado);
    console.log("enderecoLngSelecionado", enderecoLngSelecionado);

    setEndereco(enderecoSelecionado);
    setEnderecoLat(latitudeSelecionado);
    setEnderecoLng(enderecoLngSelecionado);

    setShowDetalhesEndereco(false);
  }

  async function closeModalSimpleEndereco() {
    setShowDetalhesEndereco(false);
  }

  function onKeyUpTelefone(e: any) {

    e.persist();
    e.preventDefault();



    let val = e.target.value;

    if (val.length > 15) {
      val = val.substring(0, 15);
      setTelefone(val.substring(0, 15));
      return;
    }
    if (val.length < 15) {

      setTelefone(val
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(/(\d{4})(\d)/, "$1-$2")
        .replace(/(-\d{4})(\d+?)$/, "$1"));
    }
    else {

      setTelefone(val
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(/(\d{5})(\d)/, "$1-$2")
        .replace(/(-\d{4})(\d+?)$/, "$1"));
    }


  }

  function deletarItemProdutos(index: number) {
    // Filtra a lista de produtos, removendo o item com o índice fornecido
    const updatedList = listaItemProdutos.filter((_: any, i: any) => i !== index);

    // Atualiza a lista de produtos com o item removido
    setListaItemProdutos(updatedList);

    // Calcula a nova soma dos valores após a remoção
    const totalValor = updatedList.reduce((total: any, item: any) => {
      const itemValorNumerico = parseFloat(
        item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
      );
      return total + itemValorNumerico;
    }, 0);

    // Exemplo: você pode armazenar a nova soma em outro estado ou usar conforme necessário
    console.log("Nova soma dos valores:", totalValor);

    setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));

  }

  function onChangeValor(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    // Define o desconto como zero se o campo estiver vazio
    if (v === '') {
      v = '';
    }

    setTroco(v);
  }

  const loadItemProdutos2 = () => {

    if (listaItemProdutos == undefined) return;

    if (listaItemProdutos.length === 0) {
      return (
        <>
          <IonItem lines="none">

            <IonLabel>
              <h2 style={{ "verticalAlign": "middle" }}><strong>Nenhum item cadastrado</strong></h2>
            </IonLabel>

          </IonItem>
        </>

      );
    }


    return (
      <>

        <IonGrid>
          {listaItemProdutos.map((value: any, index: any) => (
            <>

              <IonRow style={{ borderColor: '#dddddd', borderWidth: '1px', borderBottomStyle: 'dashed' }}>


                <IonCol size="9">


                  {(value.descricao && Array.isArray(value.descricao)) ? (

                    <>
                      {(value.tipo === "pizzas") ? (
                        <div>{value.tamanho} {value.quantidadeSabores} SABORES</div>
                      ) : (
                        <div>{value.categoria}</div>
                      )}
                      {value.descricao.map((item: any, index: number) => (
                        <div key={"desc" + index}>
                          - {item.descricao} (<IonLabel style={{ textAlign: "right", fontSize: "12px" }}>R$ {item.valor?.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}</IonLabel>)
                        </div>
                      ))}
                    </>

                  ) : (
                    <div key={"desc" + index}>
                      {value.descricao} (<IonLabel style={{ textAlign: "right", fontSize: "12px" }}>R$ {value.valor?.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}</IonLabel>)

                    </div>
                  )}



                </IonCol>


                <IonCol style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }} sizeXs="3" >
                  <IonLabel style={{ textAlign: "right" }}>{value.subtotal}</IonLabel>
                </IonCol>
                <IonCol sizeSm='6'>
                  <IonLabel style={{ textAlign: "right", fontSize: "12px" }} >
                    <IonChip style={{ margin: 0, padding: '5px' }}>
                      <IonIcon size='large' color='danger' icon={removeCircle} onClick={() => {

                        // Faz uma cópia da lista atual de produtos
                        let updatedList = [...listaItemProdutos];

                        // Acessa o item que será atualizado
                        let itemToUpdate = { ...updatedList[index] };

                        // Formata a quantidade atual
                        const quantidadeFormatada = parseFloat(
                          itemToUpdate.quantidade.toString().replace(/\./g, '').replace(/\,/g, '.')
                        );

                        if (quantidadeFormatada > 1) {
                          // Decrementa a quantidade
                          let quantidadeNova = quantidadeFormatada - 1;

                          // Formata o valor e calcula o novo valor com base na nova quantidade
                          const valorFormatado = parseFloat(
                            itemToUpdate.valor.toString().replace(/\./g, '').replace(/\,/g, '.')
                          );

                          // Atualiza a quantidade e o valor do item
                          itemToUpdate.quantidade = quantidadeNova;
                          const valorFloat = quantidadeNova * valorFormatado;

                          itemToUpdate.subtotal = valorFloat.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          });

                          // Substitui o item atualizado na lista copiada
                          updatedList[index] = itemToUpdate;

                          let totalValor = updatedList.reduce((total: any, item: any) => {
                            const itemValorNumerico = parseFloat(
                              item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
                            );
                            return total + itemValorNumerico;
                          }, 0);

                          setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }));

                          // Atualiza o estado com a nova lista
                          setListaItemProdutos(updatedList);

                        }
                        // else {
                        //   // Se a quantidade for 1, pode-se optar por remover o item completamente ou manter o valor como está.
                        //   // Se preferir remover o item:
                        //   updatedList.splice(index, 1);
                        //   setListaItemProdutos(updatedList);
                        // }

                      }}></IonIcon>
                      <IonLabel style={{ fontSize: '20px' }}>{value.quantidade.toLocaleString('pt-br', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })}</IonLabel>
                      <IonIcon size='large' color='success' icon={addCircle} onClick={() => {

                        // Faz uma cópia da lista atual de produtos
                        let updatedList = [...listaItemProdutos];

                        // Acessa o item que será atualizado
                        let itemToUpdate = { ...updatedList[index] };

                        // Formata e incrementa a quantidade
                        const quantidadeFormatada = parseFloat(
                          itemToUpdate.quantidade.toString().replace(/\./g, '').replace(/\,/g, '.')
                        );

                        let quantidadeNova = quantidadeFormatada + 1;

                        // Formata o valor e calcula o novo valor com base na nova quantidade
                        const valorFormatado = parseFloat(
                          itemToUpdate.valor.toString().replace(/\./g, '').replace(/\,/g, '.')
                        );

                        // Atualiza a quantidade e o valor do item
                        itemToUpdate.quantidade = quantidadeNova;
                        const valorFloat = quantidadeNova * valorFormatado;

                        itemToUpdate.subtotal = valorFloat.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        });

                        // Substitui o item atualizado na lista copiada
                        updatedList[index] = itemToUpdate;

                        let totalValor = updatedList.reduce((total: any, item: any) => {
                          const itemValorNumerico = parseFloat(
                            item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
                          );
                          return total + itemValorNumerico;
                        }, 0);

                        setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }));

                        // Atualiza o estado com a nova lista
                        setListaItemProdutos(updatedList);


                      }}></IonIcon>
                    </IonChip>
                  </IonLabel>
                </IonCol>
                <IonCol style={{ display: "flex", justifyContent: "flex-end" }} sizeSm='6'>
                  <IonButtons>
                    <IonButton
                      onClick={() => {
                        mostartAlerta({
                          backdropDismiss: false,
                          header: 'Remover item',
                          message: 'Deseja <strong>remover</strong>?',
                          buttons: [
                            {
                              text: 'Cancelar',
                              role: 'cancel',
                            },
                            {
                              text: 'Confirmar',
                              cssClass: 'primary',
                              handler: () => {
                                deletarItemProdutos(index);
                              }
                            }
                          ],
                          onDidDismiss: (e) => console.log('did dismiss'),
                        });
                      }}
                    >
                      <IonIcon color="primary" icon={trash} />
                    </IonButton>
                  </IonButtons>
                </IonCol>

              </IonRow>
            </>
          ))}
        </IonGrid>
      </>
    );
  }

  const scrollToCatalogo = () => {
    contentCatalogoRef.current && contentCatalogoRef.current.scrollIntoView();
  };

  async function adicionarItem(produto: any) {

    if (produto) {

      let updatedList = [...listaItemProdutos, produto];

      setListaItemProdutos(updatedList);

      let totalValor = updatedList.reduce((total: any, item: any) => {
        const itemValorNumerico = parseFloat(
          item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
        );
        return total + itemValorNumerico;
      }, 0);

      setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));


    }
    scrollToCatalogo();
  }

  const loadItemProdutosHistorico = (produtos: any) => {

    if (produtos == undefined) return;

    if (produtos.length === 0) {
      return (
        <>
          <IonItem lines="none">

            <IonLabel>
              <h2 style={{ "verticalAlign": "middle" }}><strong>Nenhum item cadastrado</strong></h2>
            </IonLabel>

          </IonItem>
        </>

      );
    }


    return (
      <>
        <IonGrid>
          {produtos.map((value: any, index: any) => (
            <>

              <IonRow style={{ borderColor: '#dddddd', borderWidth: '1px', borderBottomStyle: 'dashed' }}>


                <IonCol size="9">


                  {(value.descricao && Array.isArray(value.descricao)) ? (

                    <>
                      {(value.tipo === "pizzas") ? (
                        <div> {value.quantidade.toLocaleString('pt-br', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })} x {value.tamanho} {value.quantidadeSabores} SABORES</div>
                      ) : (
                        <div> {value.quantidade.toLocaleString('pt-br', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })} x {value.categoria}</div>
                      )}
                      {value.descricao.map((item: any, index: number) => (
                        <div key={"desc" + index}>
                          - {item.descricao} (<IonLabel style={{ textAlign: "right", fontSize: "12px" }}>R$ {item.valor?.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}</IonLabel>)
                        </div>
                      ))}
                    </>

                  ) : (
                    <div key={"desc" + index}>
                      {value.quantidade.toLocaleString('pt-br', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })} x {value.descricao} (<IonLabel style={{ textAlign: "right", fontSize: "12px" }}>R$ {value.valor?.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}</IonLabel>)

                    </div>
                  )}



                </IonCol>


                <IonCol style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }} sizeXs="3" >
                  <IonLabel style={{ textAlign: "right" }}>{value.subtotal}</IonLabel>
                </IonCol>

              </IonRow>
            </>
          ))}
        </IonGrid>
      </>
    );
  }

  const loadItensHistorico = () => {
    if (!arrayProjetos || arrayProjetos.length === 0) {
      return (
        <IonRow>
          <IonCol size="12" style={{ textAlign: 'center' }}>
            <IonLabel>Sem histórico de pedidos</IonLabel>
          </IonCol>
        </IonRow>
      );
    }

    return arrayProjetos.map((value: any, index: any) => (

      <IonRow key={"divprojeto" + index} style={{ borderColor: '#dddddd', borderWidth: '1px', borderBottomStyle: 'solid' }}>



        {/* Coluna de Produtos */}
        <IonCol size="10">

          <div style={{ padding: "8px" }}>
            <b>{new Date(value.dataCadastro).toLocaleString('pt-BR').substring(0, 10)} - {(window.localStorage.getItem("servicoTipo") || "Projeto") + " " + value.nomeProjeto}</b>
          </div>

          {loadItemProdutosHistorico(value.produtos)}
          <div style={{ textAlign: 'right', padding: "10px" }}>
            <b>Total: R$ {value.valorTotal}</b>
          </div>

        </IonCol>

        {/* Coluna de Ações (Botão Ver Detalhes) */}
        <IonCol size="2" style={{ textAlign: 'right', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <IonButton
            fill='clear'
            onClick={() => copiarPedido(value)}
          >
            <IonIcon icon={checkmarkCircle}></IonIcon>
          </IonButton>
        </IonCol>

      </IonRow>
    ));

  };

  const copiarPedido = (pedido: any) => {

    setListaItemProdutos(pedido.produtos);
    setFormaPagamento(pedido.formaPagamento);
    setTaxaEntrega(pedido.taxaEntrega);
    setDesconto(pedido.desconto);
    setValorTotalProdutos(pedido.valorTotalProdutos);
    setValorTotal(pedido.valorTotal);

    setShowModalHistorico(false);
  };

  async function carregarProjetosSegmentadaData(filtro: any, data: any, lojasParam?: any) {
    const consultar = async () => {

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      console.log("valor", data);
      console.log("filtroCampoSelecionadoProjeto", filtroCampoSelecionadoProjeto);
      let valor = data;
      if (data) {
        valor = data.replaceAll(/#/g, "%23");
      }
      const resp: Response = await fetch(urlBase.url + "/projetos?campo=" + (filtroCampoSelecionadoProjeto || "data") +
        "&valor=" + valor +
        "&filtro=" + filtro +
        "&lojas=" + Buffer.from(lojasParam || loja).toString('base64'),
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });
      await resp.json().then(async (res) => {
        if (resp.status === 400 || resp.status === 401) {
          setShowLoading(false);
          history.replace("/login/");
        }

        if (res && res.LastEvaluatedKey) {
          setProjetopk(res.LastEvaluatedKey.pk);
          setProjetosk(res.LastEvaluatedKey.sk);
          setProjetogsi2sk(res.LastEvaluatedKey.gsi2sk);
          setDesabilitarInfiniteScroll(false);
        }
        else {
          setDesabilitarInfiniteScroll(true);
        }

        setArrayProjetos(res.Items);
        setShowLoading(false);
      });
    }
    consultar();
  }

  async function carregarMaisProjetos($event: CustomEvent<void>) {
    console.log("carregarMaisProjetos",);
    if (projetogsi2sk.includes(filtroStatusProjeto)) {
      await carregarMaisProjetosChamada().then(() => {
      });
    }
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  async function carregarMaisProjetosChamada() {
    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    const res: Response = await fetch(urlBase.url + "/projetos?campo=" + (filtroCampoSelecionadoProjeto || "data") +
      "&valor=" + pkCliente.replaceAll(/#/g, "%23") +
      "&filtro=" + filtroStatusProjeto +
      "&isk=" + projetogsi2sk +
      "&pk=" + projetopk +
      "&sk=" + projetosk +
      "&lojas=" + Buffer.from(loja).toString('base64'),
      {
        method: urlBase.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    await res
      .json()
      .then(async (projetos) => {

        if (projetos.Items) {
          for (let i = 0; i < projetos.Items.length; i++) {
            arrayProjetos.push(projetos.Items[i]);
          }

          if (projetos.LastEvaluatedKey) {
            setProjetopk(projetos.LastEvaluatedKey.pk);
            setProjetosk(projetos.LastEvaluatedKey.sk);
            setProjetogsi2sk(projetos.LastEvaluatedKey.gsi2sk);
            setDesabilitarInfiniteScroll(false);
          }
          else {
            setProjetopk("");
            setProjetosk("");
            setProjetogsi2sk("");
            setDesabilitarInfiniteScroll(true);
          }
        }

      })
      .catch(err => console.error(err));

    if (res.status === 400 || res.status === 401) history.replace("/login/");

  }

  function cadastrarTag() {

    if (!nomeTag) {
      setTextToast("Campo Nome é obrigatório.");
      setShowToast(true);
      return;
    }

    const objectRequest = {
      nome: nomeTag
    }
    console.log("objectRequest", objectRequest);

    let id = loja;

    console.log("id", id);

    requestService(
      empresaAlterar.url.replace("{id}", id.replaceAll("#", "%23")).concat("?tag=endereco"),
      {
        method: empresaAlterar.method,
        body: JSON.stringify(objectRequest),
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
      history,
      (response: any) => {
        console.log(response);
        if (response) {
          setTextToast("Cadastro realizado com sucesso.");
          setShowToast(true);

          setDadosEmpresa(response.message.Attributes);
          setListaTagsEndereco(response.message.Attributes.tagsEndereco || []);

          // carregarEmpresa();
          // setShowModalTags(false)
          setShowModalTagsAdd(false);

        } else if (response.status === 400) {
          console.log(response);
          setTextToast("Ocorreu um erro ao efetuar o cadastro.");
          setShowToast(true);

        }
        setShowLoading(false);
      },
      (error: any) => {
        console.log(error);
        setTextToast("Ocorreu um erro ao efetuar o cadastro.");
        setShowToast(true);
        setShowLoading(false);
      })
  }

  return (
    <IonPage>

      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => {
              if (listaItemProdutos.length > 0) {
                mostartAlerta({
                  backdropDismiss: false,
                  header: 'Descartar ' + (window.localStorage.getItem("servicoTipo") || "Projeto"),
                  message: "Itens adicionados. Deseja descartar " + (window.localStorage.getItem("servicoTipo") || "Projeto") + "?",

                  buttons: [{
                    text: 'Cancelar',
                    role: 'cancel'
                  },
                  {
                    text: 'Confirmar',
                    handler: async () => {
                      history.goBack();
                    }
                  }],
                  onDidDismiss: () => { console.log('did dismiss'); }
                })
              }
              else { history.goBack(); }

            }
            } />
          </IonButtons>
          <IonTitle>Criar {(window.localStorage.getItem("servicoTipo") || "Projeto")}</IonTitle>

          <IonButtons slot="end">
            <IonButton onClick={openPopover}>
              <IonIcon slot="icon-only" icon={ellipsisVertical} />
            </IonButton>
          </IonButtons>

          <IonPopover
            isOpen={showPopover}
            event={popoverEvent}
            onDidDismiss={closePopover}
          >
            <IonList>


              <IonItem lines="none" button
                onClick={() => {
                  setShowModalTags(true); closePopover();
                }}>
                <IonIcon icon={pricetagsOutline} slot="start" />
                <IonLabel>Tags</IonLabel>
              </IonItem>

              <IonItem lines="none" button onClick={closePopover}>
                <IonIcon icon={closeOutline} slot="start" />
                <IonLabel>Cancelar</IonLabel>
              </IonItem>
            </IonList>
          </IonPopover>
        </IonToolbar>

      </IonHeader>
      <IonContent>
        <div>
          <IonCard>
            <IonCardContent>
              <IonGrid style={{ padding: "0px", margin: "0px" }}>
                <IonRow>
                  <IonCol style={{ padding: "0px", margin: "0px" }} size="12" sizeMd="12">

                    <IonSearchbar
                      ref={inputRef}
                      value={textoFiltro}
                      onIonChange={(e) => {
                        setTextoFiltro(e.detail.value);
                      }}
                      enterkeyhint="search"
                      onKeyUp={(e: any) => {
                        if (e.key === "Enter") {
                          buscarCliente(e.target.value);
                        }
                      }}
                      placeholder="Buscar"
                    />

                    <IonGrid>
                      <IonRow style={{ borderColor: 'grey', borderWidth: '1px', borderBottomStyle: 'solid' }}>
                        <IonCol style={{ padding: "0px", margin: "0px", fontSize: '25px' }} size="4">
                          <IonLabel><b>Cliente</b></IonLabel>

                        </IonCol>
                        <IonCol style={{ padding: "0px", margin: "0px", textAlign: 'right' }} size="8">
                          <IonButton size="small" disabled={!pkCliente} onClick={() => {
                            carregarProjetosSegmentadaData("Encerrado", pkCliente);
                            setShowModalHistorico(true);
                          }}>Últimos Pedidos</IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>

                    <IonGrid style={{ padding: "0px", margin: "0px" }}>
                      <IonRow>
                        <IonCol style={{ padding: "0px", margin: "0px" }} size="12" sizeMd="4">
                          <IonItem lines="none">
                            <IonLabel position="stacked">Telefone*</IonLabel>
                            <IonInput
                              ref={(ref) => campoTelefone.current = ref}
                              type="tel"
                              maxlength={15}
                              placeholder="(__) _____-____"
                              required
                              value={telefone}
                              onKeyUp={onKeyUpTelefone}
                            />
                          </IonItem>
                        </IonCol>
                        <IonCol style={{ padding: "0px", margin: "0px" }} size="12" sizeMd="8">
                          <IonItem lines="none">
                            <IonLabel position="stacked">Nome*</IonLabel>
                            <IonInput
                              placeholder="Digite o nome completo"
                              value={nome}
                              autoCorrect='true'
                              autoCapitalize='true'
                              required
                              onIonInput={(e: any) => setNome(e.target.value)}
                            />

                          </IonItem>
                        </IonCol>

                      </IonRow>
                      <IonRow>
                        <IonCol style={{ padding: "0px", margin: "0px" }} size="12" sizeMd="8">
                          <IonItem lines="none">
                            <IonLabel position="stacked">Endereço*</IonLabel>
                            <IonInput
                              value={endereco}
                              autoCorrect='true'
                              autoCapitalize='true'
                              placeholder="Ex: Avenida Paulista, 111, São Paulo, SP"
                              onIonChange={() => { setEnderecoLat(""); setEnderecoLng(""); }}
                              onIonInput={(e: any) => setEndereco(e.target.value)}
                            />
                            <IonButton slot="end" style={{ textAlign: 'right' }} onClick={() => { setShowDetalhesEndereco(true); }}>Mapa</IonButton>
                          </IonItem>

                          <IonGrid>
                            <IonRow>
                              {listaTagsEndereco?.map((opcao: any, index: any) => (
                                <IonCol size="auto" key={index}> {/* size="6" coloca 2 botões por linha */}
                                  <IonButton
                                    fill="outline"
                                    style={{ height: "30px", fontSize: "12px", margin: "0px" }}
                                    expand="block"
                                    shape="round"
                                    onClick={() => {
                                      setEndereco(endereco + opcao);
                                      // setfocu
                                    }}
                                  >
                                    {opcao.toUpperCase()}
                                  </IonButton>
                                </IonCol>
                              ))}
                            </IonRow>
                          </IonGrid>




                        </IonCol>
                        <IonCol style={{ padding: "0px", margin: "0px" }} size="12" sizeMd="4">
                          <IonItem lines="none">
                            <IonLabel position="stacked">Complemento</IonLabel>
                            <IonInput
                              value={complemento}
                              autoCorrect='true'
                              autoCapitalize='true'
                              placeholder="Digite o complemento"
                              onIonInput={(e: any) => setComplemento(e.target.value)}
                            />
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>

        </div>

        <IonGrid style={{ padding: "0px", margin: "0px" }} ref={contentCatalogoRef}>
          <IonRow>

            <IonCol style={{ padding: "0px", margin: "0px" }} sizeMd="8" size="12">

              <ProjetosProdutosComporNovoMemory
                empresa={dadosEmpresa}
                disabled={false}
                adicionarItem={adicionarItem}
              />

            </IonCol>

            <IonCol style={{ padding: "0px", margin: "0px" }} sizeMd="4" size="12">
              <IonCard>
                <IonCardContent>

                  <IonGrid style={{ padding: "0px", margin: "0px" }}>

                    <IonRow style={{ borderColor: 'grey', fontSize: '25px', borderWidth: '1px', borderBottomStyle: 'solid' }}>
                      <IonCol style={{ padding: "0px", margin: "0px" }} size="8"><IonLabel><b>Lista de itens</b></IonLabel></IonCol>
                      <IonCol style={{ padding: "0px", margin: "0px" }} size="4"><IonLabel style={{ display: 'block', textAlign: "right" }}><b>{valorTotalProdutos}</b></IonLabel></IonCol>

                    </IonRow>

                  </IonGrid>

                  {loadItemProdutos2()}

                  <IonGrid style={{ margin: "0px" }}>
                    <IonRow style={{ borderColor: 'grey', fontSize: '25px', borderWidth: '1px', borderBottomStyle: 'solid' }}>
                      <IonCol style={{ padding: "0px", margin: "0px" }} size="12"><IonLabel><b>Pagamento</b></IonLabel></IonCol>
                    </IonRow>
                  </IonGrid>


                  <IonGrid style={{ padding: "0px", margin: "0px" }}>
                    <IonRow>
                      <IonCol>
                        <IonList>
                          <IonItem lines="none">
                            <IonLabel position="stacked">Forma de pagamento*</IonLabel>
                            <IonSelect
                              placeholder="Selecione"
                              value={formaPagamento}
                              onIonChange={(e) => {
                                setFormaPagamento(e.detail.value);
                              }}
                            >
                              {opcoesPagamento.map((opcao, index) => (
                                <IonSelectOption key={index} value={opcao}>
                                  {opcao}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          </IonItem>

                          <IonItem lines="none" hidden={formaPagamento != "Dinheiro"}>
                            <IonLabel position="stacked">Troco</IonLabel>
                            <IonInput
                              type="tel"
                              maxlength={14}
                              placeholder="Digite o valor"
                              value={troco}
                              onKeyUp={onChangeValor}
                            />
                          </IonItem>

                          <IonItem lines="none">
                            <IonLabel position="stacked">Taxa de entrega*</IonLabel>
                            <IonSelect
                              placeholder="Selecione"
                              value={taxaEntrega}
                              onIonChange={(e) => setTaxaEntrega(e.detail.value)}
                            >
                              {opcoesTaxaEntrega.map((opcao, index) => (
                                <IonSelectOption key={index} value={opcao.value}>
                                  {opcao.label}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          </IonItem>

                          <IonItem lines="none">
                            <IonLabel position="stacked">Desconto</IonLabel>
                            <IonSelect
                              placeholder="Selecione"
                              value={desconto}
                              onIonChange={(e) => setDesconto(e.detail.value)}
                            >
                              {opcoesDesconto.map((opcao, index) => (
                                <IonSelectOption key={index} value={opcao.value}>
                                  {opcao.label}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          </IonItem>
                        </IonList>
                      </IonCol>
                    </IonRow>
                  </IonGrid>

                  <IonGrid style={{ padding: "0px", margin: "0px" }}>
                    <IonRow style={{ borderColor: 'grey', fontSize: '25px', borderWidth: '1px', borderTopStyle: 'solid' }}>
                      <IonCol><IonLabel><b>Total</b></IonLabel></IonCol>
                      <IonCol><IonLabel style={{ display: 'block', textAlign: "right" }}><b>{valorTotal}</b></IonLabel></IonCol>
                    </IonRow>
                  </IonGrid>


                  <IonToolbar>
                    <IonButton
                      onClick={async () => {
                        avancar();
                      }}
                      expand="block"
                      type="submit"
                    >
                      Registrar
                    </IonButton>
                  </IonToolbar>


                </IonCardContent>
              </IonCard>
            </IonCol>

          </IonRow>
        </IonGrid>



        {/* <IonModal
          isOpen={showDetalhesCompor} onDidDismiss={() => { setShowDetalhesCompor(false); }}>


          <ProjetosProdutosComporNovoMemoryModal empresa={dadosEmpresa} closeAction={adicionarItem} closeActionSimple={closeModalProdutosCompor}></ProjetosProdutosComporNovoMemoryModal>

        </IonModal> */}


        <IonModal
          onDidPresent={() => {
            if (inputRefEndereco.current) {
              inputRefEndereco.current.setFocus();
            }
          }} isOpen={showDetalhesEndereco} onDidDismiss={() => { setShowDetalhesEndereco(false); }}>
          <Endereco endereco={endereco} inputRef={inputRefEndereco} closeAction={closeModalEndereco} closeActionSimple={closeModalSimpleEndereco}></Endereco>
        </IonModal>


        <IonModal isOpen={showModalTags} onDidDismiss={() => { setShowModalTags(false); }}>
          <IonHeader>

            <IonToolbar color="primary">
              <IonButtons slot="start">
                <IonIcon slot="icon-only" icon={arrowBack} onClick={() => setShowModalTags(false)} />
              </IonButtons>
              <IonTitle>Tags</IonTitle>

            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol size="12">

                  <IonList>
                    {loadTagsEndereco()}
                  </IonList>

                  <IonModal onDidPresent={() => {
                    if (inputRefTag.current) {
                      inputRefTag.current.setFocus();
                    }
                  }} isOpen={showModalTagsAdd} onDidDismiss={() => {
                    setShowModalTagsAdd(false);
                    setNome("");
                  }}>


                    <>
                      <IonHeader>
                        <IonToolbar color="primary">
                          <IonButtons slot="start">
                            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => setShowModalTagsAdd(false)} />
                          </IonButtons>
                          <IonTitle>Nova tag</IonTitle>
                        </IonToolbar>
                      </IonHeader>
                      <IonContent>
                        <IonGrid>
                          <IonRow>
                            <IonCol>
                              <IonList>

                                <IonItem lines="none">
                                  <IonLabel position="stacked">Nome*</IonLabel>
                                  <IonInput placeholder="Digite o nome da tag" value={nomeTag} ref={(ref) => inputRefTag.current = ref} autoCorrect='true' autoCapitalize='true' required onIonInput={(e: any) => setNomeTag(e.target.value)}></IonInput>
                                </IonItem>
                              </IonList>
                            </IonCol>
                          </IonRow>
                        </IonGrid>

                      </IonContent>
                      <IonFooter className="ion-no-border">
                        <IonToolbar>
                          <IonButton onClick={() => cadastrarTag()} expand="block" type="submit">Cadastrar</IonButton>
                        </IonToolbar>
                      </IonFooter>
                    </>

                  </IonModal>

                  <IonInfiniteScroll
                    threshold="100px"
                    onIonInfinite={(e: CustomEvent<void>) => carregarMaisProjetos(e)}
                    position="bottom"
                    disabled={desabilitarInfiniteScroll}
                  >
                    <IonInfiniteScrollContent
                      loadingSpinner="dots"
                      loadingText="Carregando mais itens..."
                    ></IonInfiniteScrollContent>
                  </IonInfiniteScroll>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
          <IonFooter className="ion-no-border">
            <IonToolbar>
              <IonButton onClick={() => { setNomeTag(""); setShowModalTagsAdd(true); }} expand="block" type="submit">Nova</IonButton>
            </IonToolbar>
          </IonFooter>

        </IonModal>


        {/* Modal que contém a lista de pedidos */}
        <IonModal isOpen={showModalHistorico} onDidDismiss={() => { setShowModalHistorico(false); }}>
          <IonHeader>

            <IonToolbar color="primary">
              <IonButtons slot="start">
                <IonIcon slot="icon-only" icon={arrowBack} onClick={() => setShowModalHistorico(false)} />
              </IonButtons>
              <IonTitle>Histórico de Pedidos</IonTitle>

            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol size="12">
                  <div>
                    {loadItensHistorico()}
                  </div>

                  <IonInfiniteScroll
                    threshold="100px"
                    onIonInfinite={(e: CustomEvent<void>) => carregarMaisProjetos(e)}
                    position="bottom"
                    disabled={desabilitarInfiniteScroll}
                  >
                    <IonInfiniteScrollContent
                      loadingSpinner="dots"
                      loadingText="Carregando mais itens..."
                    ></IonInfiniteScrollContent>
                  </IonInfiniteScroll>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>

        </IonModal>

      </IonContent>



      <IonToast
        isOpen={showToast}
        position="middle" //top, bottom, middle
        onDidDismiss={() => setShowToast(false)}
        message={textToast}
        duration={3000}
      />


      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        onDidDismiss={() => { setShowLoading(false); }}
        spinner='circles'
      />

    </IonPage >
  );
};

export default ProjetosNovoPedidoVenda;