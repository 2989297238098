// import '@capacitor-community/http';
import { BleClient } from '@capacitor-community/bluetooth-le';
import { Camera, CameraDirection, CameraResultType, CameraSource } from '@capacitor/camera';
import { IonAlert, IonButton, IonButtons, IonCard, IonCardContent, IonContent, IonDatetime, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonPopover, IonRadio, IonRadioGroup, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToast, IonToggle, IonToolbar, isPlatform, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import imageCompression from 'browser-image-compression';
import { Buffer } from 'buffer';
import { addCircle, alertOutline, arrowBack, closeOutline, copy, ellipsisVertical, informationCircleOutline, listOutline, pencilOutline, timerOutline, trashOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { apiIfoodAouthUserCode, apiIfoodAouthUserToken, apiIfoodDeleteMerchantsInterruptions, apiIfoodMerchants, apiIfoodMerchantsDetails, apiIfoodMerchantsInterruptions, apiIfoodMerchantsOpeningHours, apiIfoodMerchantsStatus, apiIfoodPostMerchantsInterruptions, apiIfoodPutMerchantsOpeningHours, buscarEndereco, empresaAlterar, empresaConfiguracoesCarregar, requestService } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import Bluetooth from '../Bluetooth/Bluetooth';
import './ContaAlt.css';
import iconIfood from "./imgs/ifoodBigIcon.svg";

type Props = {
  abrirMenu: boolean;
};

const ContaAlt: React.FC<Props> = (props: Props) => {

  const history = useHistory();

  const [showToast2, setShowToast2] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");

  const [showLoading, setShowLoading] = useState(true);
  const [showSucesso, setShowSucesso] = useState(false);

  const { loja, setLoja } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const [empresaNome, setEmpresaNome] = useState('');
  const [empresaEndereco, setEmpresaEndereco] = useState('');
  const [empresaTelefone, setEmpresaTelefone] = useState('');
  const [empresaLatitude, setEmpresaLatitude] = useState('');
  const [empresaLongitude, setEmpresaLongitude] = useState('');
  const [urlLogo, setUrlLogo] = useState('');

  const [impressoraLargura, setImpressoraLargura] = useState('');

  const [impressoraLocalHabilitar, setImpressoraLocalHabilitar] = useState<boolean>(false);
  const [impressoraLocalName, setImpressoraLocalName] = useState('');
  const [impressorasLocal, setImpressorasLocal] = useState<string[]>([]);

  const [impressoraRedeHabilitar, setImpressoraRedeHabilitar] = useState<boolean>(false);
  const [impressoraRedeIp, setImpressoraRedeIp] = useState('');
  const [impressoraRedePorta, setImpressoraRedePorta] = useState('');

  const [impressoraBluetoothHabilitar, setImpressoraBluetoothHabilitar] = useState<boolean>(false);
  const [impressoraBluetoothDeviceId, setImpressoraBluetoothDeviceId] = useState('');

  const [impressaoAutomaticaIfood, setImpressaoAutomaticaIfood] = useState<boolean>(false);

  const [condicoesGerais, setCondicoesGerais] = useState('');


  const [nomeInternoMesaOuComanda, setNomeInternoMesaOuComanda] = useState('');
  const [nomeInternoMesasOuComandas, setNomeInternoMesasOuComandas] = useState('');
  const [imprimirMesaParcial, setImprimirMesaParcial] = useState<boolean>(false);


  const [servicoTipo, setServicoTipo] = useState('');
  const [servicoTipoPlural, setServicoTipoPlural] = useState('');
  const [nomeLinhaDoTempo, setNomeLinhaDoTempo] = useState('');
  const [taxaServico, setTaxaServico] = useState('');
  const [identificacaoPorNumero, setIdentificacaoPorNumero] = useState<boolean>(false);
  const [layoutSimplificado, setLayoutSimplificado] = useState<boolean>(false);
  const [zerarValoresVenda, setZerarValoresVenda] = useState<boolean>(false);
  const [esconderValoresProjeto, setEsconderValoresProjeto] = useState<boolean>(false);
  const [tipoAgendamentoPadrao, setTipoAgendamentoPadrao] = useState('');
  const [servicoPeriodoHoraEmpresa, setServicoPeriodoHoraEmpresa] = useState<boolean>(false);
  const [parametroMostrarHorario, setParametroMostrarHorario] = useState<boolean>(false);
  const [enderecoPrincipalEmpresa, setEnderecoPrincipalEmpresa] = useState<boolean>(false);
  const [enviarGoogleAgendaCliente, setEnviarGoogleAgendaCliente] = useState<boolean>(false);
  const [enviarGoogleAgendaResponsavel, setEnviarGoogleAgendaResponsavel] = useState<boolean>(false);
  const [instrucoesMotorista, setInstrucoesMotorista] = useState('');

  const [enviarMsgClienteCadastro, setEnviarMsgClienteCadastro] = useState({ S: "não" });
  const [enviarMsgClienteProjeto, setEnviarMsgClienteProjeto] = useState({ S: "não" });
  const [enviarMsgResposavelProjeto, setEnviarMsgResposavelProjeto] = useState({ S: "não" });
  const [enviarMsgClienteAgendamento, setEnviarMsgClienteAgendamento] = useState({ S: "não" });
  const [enviarMsgResposavelAgendamento, setEnviarMsgResposavelAgendamento] = useState({ S: "não" });
  const [enviarMsgResposavelOperacao, setEnviarMsgResposavelOperacao] = useState({ S: "não" });
  const [enviarMsgClienteOperacao, setEnviarMsgClienteOperacao] = useState({ S: "não" });

  const [mostartAlerta] = useIonAlert();
  const [localEnderecoSelecionado, setLocalEnderecoSelecionado] = useState<any>("");
  const [listaEnderecos, setListaEnderecos] = useState<any>([]);
  const [ativarIfood1, setAtivarIfood1] = useState<boolean>(false);
  const [userCode, setUserCode] = useState<any>();
  const [authorizationCodeVerifier, setAuthorizationCodeVerifier] = useState<any>();
  const [ativarIfood2, setAtivarIfood2] = useState<boolean>(false);
  const [authorizationCode, setAuthorizationCode] = useState<any>();


  const [showLojasIfood, setShowLojasIfood] = useState<boolean>(false);
  const [listaLojasIfood, setListaLojasIfood] = useState<any>([]);

  const [showDetalheLojaIfood, setShowDetalheLojaIfood] = useState<boolean>(false);
  const [detalheLojaIfood, setDetalheLojaIfood] = useState<any>();

  const [showStatusLojaIfood, setShowStatusLojaIfood] = useState<boolean>(false);
  const [statusLojaIfood, setStatusLojaIfood] = useState<any>([]);

  const [showInterruptionsLojaIfood, setShowInterruptionsLojaIfood] = useState<boolean>(false);
  const [interruptionsLojaIfood, setInterruptionsLojaIfood] = useState<any>([]);

  const [showFecharLojaIfood, setShowFecharLojaIfood] = useState<boolean>(false);
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [selectedReason, setSelectedReason] = useState<string>("");

  const [showPausaProgramada, setShowPausaProgramada] = useState<boolean>(false);
  const [pauseTitle, setPauseTitle] = useState<any>(''); // Armazena o título da pausa programada
  const [startDate, setStartDate] = useState<any>(''); // Armazena a data de início
  const [startTime, setStartTime] = useState<any>(''); // Armazena a hora de início
  const [endDate, setEndDate] = useState<any>(''); // Armazena a data de término
  const [endTime, setEndTime] = useState<any>(''); // Armazena a hora de término

  const [showOpenHoursLojaIfood, setShowOpenHoursLojaIfood] = useState<boolean>(false);
  const [openHoursLojaIfood, setOpenHoursLojaIfood] = useState<any>([]);

  const [showAlterarHorarioLojaIfood, setShowAlterarHorarioLojaIfood] = useState<boolean>(false);




  const [showPopoverGrid, setShowPopoverGrid] = useState(false);
  const [popoverEventGrid, setPopoverEventGrid] = useState(null);
  const [itemSelecionado, setItemSelecionado] = useState<any>(null); // Variável para armazenar o item clicado

  const [showModalBlueTooth, setShowModalBlueTooth] = useState(false);


  const [idOpenHour, setIdOpenHour] = useState<any>();
  const [dayOfWeekOpenHour, setDayOfWeekOpenHour] = useState<any>();
  const [startOpenHour, setStartOpenHour] = useState<any>();
  const [durationOpenHour, setDurationOpenHour] = useState<any>();

  const openPopoverGrid = (e: any, item: any) => {
    e.persist();
    setPopoverEventGrid(e); // Seta o evento para abrir o popover
    setItemSelecionado(item); // Seta o item clicado
    setShowPopoverGrid(true); // Abre o popover
  };

  const closePopoverGrid = () => {
    setShowPopoverGrid(false); // Fecha o popover
    setPopoverEventGrid(null); // Reseta o evento
    setItemSelecionado(null); // Reseta o item selecionado
  };


  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    buscarImpressorasLocal();
    carregarConta();
    atualizarTamanhoImagem();
  });

  function carregarConta() {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;

      requestService(empresaConfiguracoesCarregar.url.replace("{id}", id.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
        method: empresaConfiguracoesCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response);
          setEmpresaNome(response.Item.empresaNome || '');
          setEmpresaEndereco(response.Item.empresaEndereco || '');
          setEmpresaTelefone(response.Item.empresaTelefone || '');
          setEmpresaLatitude(response.Item.empresaLatitude || '');
          setEmpresaLongitude(response.Item.empresaLongitude || '');
          setUrlLogo(response.Item.urlLogo || '');
          setCondicoesGerais(response.Item.condicoesGerais || '');

          setImpressoraLargura(response.Item.impressoraLargura || '');

          //grava no dynamo
          setImpressoraRedeHabilitar(response.Item.impressoraRedeHabilitar || false);
          setImpressoraRedeIp(response.Item.impressoraRedeIp || '');
          setImpressoraRedePorta(response.Item.impressoraRedePorta || '');

          //grava no localStorage
          const isLocalEnabled = window.localStorage.getItem("impressoraLocalHabilitar") === "true";
          setImpressoraLocalHabilitar(isLocalEnabled);
          setImpressoraLocalName(window.localStorage.getItem("impressoraLocalName") || "");

          //grava no localStorage
          const isBluetoothEnabled = window.localStorage.getItem("impressoraBluetoothHabilitar") === "true";
          setImpressoraBluetoothHabilitar(isBluetoothEnabled);
          setImpressoraBluetoothDeviceId(window.localStorage.getItem("impressoraBluetoothDeviceId") || "");

          setImpressaoAutomaticaIfood(response.Item.impressaoAutomaticaIfood || false);

          setNomeInternoMesaOuComanda(response.Item.nomeInternoMesaOuComanda || '');
          setNomeInternoMesasOuComandas(response.Item.nomeInternoMesasOuComandas || '');
          setImprimirMesaParcial(response.Item.imprimirMesaParcial || false);
          setServicoTipo(response.Item.servicoTipo || '');
          setServicoTipoPlural(response.Item.servicoTipoPlural || '');
          setNomeLinhaDoTempo(response.Item.nomeLinhaDoTempo || '');
          setTaxaServico(response.Item.taxaServico || '');
          setIdentificacaoPorNumero(response.Item.identificacaoPorNumero || false);
          setLayoutSimplificado(response.Item.layoutSimplificado || false);
          setZerarValoresVenda(response.Item.zerarValoresVenda || false);
          setEsconderValoresProjeto(response.Item.esconderValoresProjeto || false);
          setTipoAgendamentoPadrao(response.Item.tipoAgendamentoPadrao || '');
          setServicoPeriodoHoraEmpresa(response.Item.servicoPeriodoHoraEmpresa || false);
          setParametroMostrarHorario(response.Item.parametroMostrarHorario || false);
          setEnderecoPrincipalEmpresa(response.Item.enderecoPrincipalEmpresa || false);
          setEnviarGoogleAgendaCliente(response.Item.enviarGoogleAgendaCliente || false);
          setEnviarGoogleAgendaResponsavel(response.Item.enviarGoogleAgendaResponsavel || false);
          setInstrucoesMotorista(response.Item.instrucoesMotorista || '');

          setEnviarMsgClienteCadastro({ S: response.Item.enviarMsgClienteCadastro || "não" });
          setEnviarMsgClienteProjeto({ S: response.Item.enviarMsgClienteProjeto || "não" });
          setEnviarMsgResposavelProjeto({ S: response.Item.enviarMsgResposavelProjeto || "não" });
          setEnviarMsgClienteAgendamento({ S: response.Item.enviarMsgClienteAgendamento || "não" });
          setEnviarMsgResposavelAgendamento({ S: response.Item.enviarMsgResposavelAgendamento || "não" });
          setEnviarMsgResposavelOperacao({ S: response.Item.enviarMsgResposavelOperacao || "não" });
          setEnviarMsgClienteOperacao({ S: response.Item.enviarMsgClienteOperacao || "não" });

          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }

  function atualizarTamanhoImagem() {
    var imagemLogo: any;
    imagemLogo = document.getElementById('imagemLogo');
    var img = new Image();
    img.onload = function () {
      var proporcao = img.width / img.height;
      var novaLargura = 250 * proporcao;
      imagemLogo.style.width = novaLargura + 'px';
    };
    img.src = imagemLogo.src;

  }


  const cadastrar = () => {
    setShowToast2(true);
  }


  const takePhoto = async (indice: string) => {

    const cameraPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      allowEditing: false,
      direction: CameraDirection.Rear,
      source: CameraSource.Prompt,
      promptLabelHeader: 'Fotos',
      promptLabelPhoto: 'Biblioteca de fotos',
      promptLabelPicture: 'Câmera',
      promptLabelCancel: 'Cancelar'
    });

    var binary_string = window.atob(cameraPhoto.base64String || '');
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    const blob = new Blob([new Uint8Array(bytes.buffer)], {
      type: `image/${cameraPhoto.format}`,
    });

    const file = new File([blob], "Name", {
      lastModified: new Date().getTime(),
      type: blob.type,
    });

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1102,
      useWebWorker: true,
      initialQuality: 0.5
    }
    try {
      const compressedFile = await imageCompression(file, options);
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB


      let base64 = await getBase64(compressedFile);
      await incluirFoto(indice, base64);

    } catch (error) {
      console.log(error);
    }

  };

  async function getBase64(file: any) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(file)
    })
  }

  async function incluirFoto(indice: string, base64: any) {


    const atualizar = async () => {

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }


      let parametros = {
        base64: base64
      }

      try {
        setShowLoading(true);

        const resp = await fetch(empresaAlterar.url.replace("{id}", loja.replaceAll("#", "%23")).concat("?tipo=logoempresa"),
          {
            method: empresaAlterar.method,
            headers: [
              ["token", token]
            ],
            body: JSON.stringify(parametros),
            credentials: 'include'
          });




        const response = await resp.json();

        console.log("response", response);
        if (response.Item) {
          setUrlLogo(response.Item);
          atualizarTamanhoImagem();

        }
        setShowLoading(false);


      } catch (error) {
        console.log("erro", error);
        setShowLoading(false);
      }
    }

    atualizar();

  };

  function buscarLocalizacao(endereco: any) {
    if (endereco.replace(/\s/g, "") == "") return;

    let enderecoTratado = endereco.toLowerCase().replace(/\s/g, "+");


    requestService(buscarEndereco.url.replace("{id}", enderecoTratado), {

      method: buscarEndereco.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      credentials: 'include'
    },
      history,
      (response: any) => {
        if (response && response.Item) {
          try {
            setEmpresaLatitude(response.Item.enderecoLat);
            setEmpresaLongitude(response.Item.enderecoLng);

            setTextToast("Endereço validado com sucesso.");
            setShowToast(true);
          } catch (error) {
            console.log("error", error);
          }

        }
      },
      (error: any) => {
        console.log("e1", error);
        setTextToast(error.message);
        setShowToast(true);
        if (error.data) {
          console.log("entrou varios endereços");
          if (Array.isArray(error.data) && error.data.length > 0) {
            setListaEnderecos(error.data);
          }
        }
      }).finally(() => { setShowLoading(false); });
    ;
  }

  function readListaEnderecos() {
    return listaEnderecos.map((value: any, index: any) => (
      <IonItem
        key={"endereco" + value.place_id}
        button
        onClick={() => setLocalEnderecoSelecionado(value.formatted_address)}
        color={localEnderecoSelecionado === value.formatted_address ? 'tertiary' : ''}
      >
        <IonLabel>{value.formatted_address}</IonLabel>
      </IonItem>
    ));
  }


  function getButtonsGrid(value: any) {

    let resultado: any = [];

    if (acessos?.configuracoes == 'edicao') {


      resultado.push(
        {
          text: 'Detalhes',
          disabled: false,
          icon: listOutline,
          handler: () => {
            getMerchantsDetail();
          }
        });


      resultado.push(
        {
          text: 'Status',
          disabled: false,
          icon: informationCircleOutline,
          handler: () => {
            getMerchantsStatus();
          }
        });
      resultado.push(
        {
          text: 'Interrupções',
          disabled: false,
          icon: alertOutline,
          handler: () => {
            getMerchantsInterruptions();
          }
        });

      resultado.push(
        {
          text: 'Horários',
          disabled: false,
          icon: timerOutline,
          handler: () => {
            getMerchantsOpenHours();
          }
        });
      // resultado.push(
      //   {
      //     text: 'Excluir',
      //     disabled: false,
      //     icon: trashOutline,
      //     handler: () => {
      //       mostartAlerta({
      //         backdropDismiss: false,
      //         header: "Remover item",
      //         message: "Deseja <strong>remover</strong>?",
      //         buttons: [
      //           { text: "Cancelar", role: "cancel" },
      //           {
      //             text: "Confirmar",
      //             cssClass: "primary",
      //             // handler: () => deletarItemCatalogoProdutos(value.indice),
      //           },
      //         ],
      //       });
      //     }
      //   }
      // );
    }


    resultado.push(
      {
        text: 'Cancelar',
        disabled: false,
        icon: closeOutline,
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      });


    return resultado;
  }

  function readListalojasIfood() {
    return (
      <>
        <IonPopover
          isOpen={showPopoverGrid}
          event={popoverEventGrid}
          onDidDismiss={closePopoverGrid}
        >
          <IonList>
            {getButtonsGrid(itemSelecionado).map((button: any, btnIndex: number) => (
              <IonItem key={btnIndex} lines="none" disabled={button.disabled} button onClick={() => {
                if (button.handler) button.handler();
                closePopoverGrid(); // Fecha o popover após a ação
              }}>
                <IonIcon icon={button.icon} slot="start" />
                <IonLabel>{button.text}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        </IonPopover>


        {listaLojasIfood.map((value: any, index: any) => (

          <IonItem
            key={"lojasifood" + value.id}
            button
            onClick={() => window.localStorage.setItem("merchantIdIfood", value.id)}
            color={window.localStorage.getItem("merchantIdIfood") === value.id ? 'tertiary' : ''}
          >
            <IonLabel>{value.name}</IonLabel>
            <div style={{ position: "absolute", right: "6px", zIndex: 9999999, textAlign: "right" }}>

              <IonButtons slot="end">
                <IonButton color="primary" onClick={(e) => openPopoverGrid(e, value)}>
                  <IonIcon slot="icon-only" icon={ellipsisVertical} />
                </IonButton>
              </IonButtons>

            </div>
          </IonItem>
        ))}
      </>
    );


  }

  const handlePortaInput = (e: any) => {
    const inputValue = e.target.value;

    // Remove qualquer caractere não numérico e limita a 4 dígitos
    const numericValue = inputValue.replace(/\D/g, '').slice(0, 4);

    setImpressoraRedePorta(numericValue);
  };

  function gerarEscPos(imprimir58mm: any = false) {
    // Função para remover acentos e cedilhas
    function removerAcentosECedilha(texto: string): string {
      return texto
        .normalize('NFD')  // Normaliza o texto para decompô-lo em caracteres base e acentos
        .replace(/[\u0300-\u036f]/g, '')  // Remove os acentos
        .replace(/ç/g, 'c')  // Substitui 'ç' por 'c'
        .replace(/Ç/g, 'C');  // Substitui 'Ç' por 'C'
    }

    function gerarTexto() {
      let texto = "";

      texto += imprimir58mm ? `********************************\n` : `**************************************\n`;
      texto += `Conexão realizada com sucesso!\n`;
      texto += imprimir58mm ? `********************************\n` : `**************************************\n`;

      return texto;
    }

    try {


      console.log("dadosImpressao1");
      // Montando o cupom com os dados no formato ESC/POS
      const texto = gerarTexto();

      // Comandos ESC/POS básicos

      const ESC = 0x1B;  // ESC
      const LF = 0x0A;   // Line Feed
      const CORTA_PAPEL = [ESC, 0x69];   // Corta Papel
      const NEGRITO_ON = [ESC, 0x45, 0x01];  // Ativar negrito
      const NEGRITO_OFF = [ESC, 0x45, 0x00]; // Desativar negrito
      const ALIMENTAR_PAPEL = [ESC, 0x64, 0x10]; // Alimentação de papel


      const dadosImpressao: any = [
        // ...ALIMENTAR_PAPEL, // Alimentação de papel
        LF,

        ...removerAcentosECedilha(texto).split("").map(c => c.charCodeAt(0)),

        LF, LF, LF, LF, LF,
        // ...ALIMENTAR_PAPEL, // Alimentação de papel
        ...CORTA_PAPEL

      ];

      // Retorna os dados como Uint8Array
      return new Uint8Array(dadosImpressao);
    } catch (error) {
      console.error('Erro ao imprimir cupom:', error);
      return null; // Retorna null em caso de erro
    }
  }

  function getUserCode() {

    const consultar = async () => {

      setShowLoading(true);

      console.log("aasasasasa", loja);
      let id = loja;

      requestService(apiIfoodAouthUserCode.url, {
        method: apiIfoodAouthUserCode.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response.data);
          setUserCode(response.data.userCode || '');
          setAuthorizationCodeVerifier(response.data.authorizationCodeVerifier || '');
          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }

  function getTokenIfood() {

    const consultar = async () => {

      setShowLoading(true);

      console.log("aasasasasa", loja);
      let id = loja;

      const objectRequest = {
        authorizationCode,
        authorizationCodeVerifier
      }

      requestService(apiIfoodAouthUserToken.url, {
        method: apiIfoodAouthUserToken.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(objectRequest),
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response);

          setTextToast("Integração realizada com sucesso.");
          setShowToast(true);

          window.localStorage.setItem("integradoIfood", "true");

          getMerchants();

          setShowLoading(false);
          setAtivarIfood2(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }

  function getMerchants() {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;

      requestService(apiIfoodMerchants.url.replace("{id}", id.replace(/#/g, '%23')), {
        method: apiIfoodMerchants.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response.data);

          if (response.data) {


            // Verifica se "merchantIdIfood" está armazenado no localStorage
            let merchantId: any = window.localStorage.getItem("merchantIdIfood");

            if (!merchantId) {
              // Caso não esteja definido, verifica se há itens em `listaLojasIfood`
              if (listaLojasIfood && listaLojasIfood.length > 0) {
                // Define o ID do primeiro item da lista no localStorage
                merchantId = listaLojasIfood[0].id;
                window.localStorage.setItem("merchantIdIfood", merchantId);
                console.log("merchantIdIfood definido como:", merchantId);
              } else {
                console.error("A lista de lojas está vazia ou não definida.");
              }
            } else {
              console.log("merchantIdIfood já definido como:", merchantId);
            }



            setListaLojasIfood(response.data);
            setShowLojasIfood(true);

            // window.localStorage.setItem("merchantIdIfood", response.data[0].id);

            // setTextToast("Integração validada com sucesso para empresa " + response.data[0].name + ".");
            // setShowToast(true);
          }

        },
        (error: any) => {
          console.log(error);
          setTextToast(error.message);
          setShowToast(true);
        });

    }

    consultar();
  }

  function getMerchantsDetail() {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;
      let merchantId = window.localStorage.getItem("merchantIdIfood") || "";
      console.log("aasasasasa", merchantId);
      requestService(apiIfoodMerchantsDetails.url.replace("{id}", id.replace(/#/g, '%23')).replace("{merchantId}", merchantId), {
        method: apiIfoodMerchantsDetails.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response.data);

          if (response.data) {
            setDetalheLojaIfood(response.data);
            setShowDetalheLojaIfood(true);
          }

        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }


  function getMerchantsStatus() {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;
      let merchantId = window.localStorage.getItem("merchantIdIfood") || "";
      console.log("aasasasasa", merchantId);
      requestService(apiIfoodMerchantsStatus.url.replace("{id}", id.replace(/#/g, '%23')).replace("{merchantId}", merchantId), {
        method: apiIfoodMerchantsStatus.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response.data);

          if (response.data) {
            setStatusLojaIfood(response.data);
            setShowStatusLojaIfood(true);
          }

        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }

  function getMerchantsInterruptions() {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;
      let merchantId = window.localStorage.getItem("merchantIdIfood") || "";
      console.log("aasasasasa", merchantId);
      requestService(apiIfoodMerchantsInterruptions.url.replace("{id}", id.replace(/#/g, '%23')).replace("{merchantId}", merchantId), {
        method: apiIfoodMerchantsInterruptions.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response.data);

          if (response.data) {
            setInterruptionsLojaIfood(response.data);
            setShowInterruptionsLojaIfood(true);
          }

        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }

  function getMerchantsOpenHours() {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;
      let merchantId = window.localStorage.getItem("merchantIdIfood") || "";
      console.log("aasasasasa", merchantId);
      requestService(apiIfoodMerchantsOpeningHours.url.replace("{id}", id.replace(/#/g, '%23')).replace("{merchantId}", merchantId), {
        method: apiIfoodMerchantsOpeningHours.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response.data);

          if (response.data) {
            setOpenHoursLojaIfood(response.data);
            setShowOpenHoursLojaIfood(true);
          }

        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }

  function putMerchantsOpenHours() {

    const enviar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;
      let merchantId = window.localStorage.getItem("merchantIdIfood") || "";
      console.log("aasasasasa", merchantId);


      let shiftsAlterado = openHoursLojaIfood.shifts.map((item: any) => {
        // Verifica se o item corresponde ao 'dayOfWeek' fornecido
        if (item.id === idOpenHour) {
          // Atualiza o 'start' e 'duration' com os valores fornecidos
          return {
            ...item,
            dayOfWeek: dayOfWeekOpenHour,
            start: startOpenHour,
            duration: durationOpenHour, // Ajusta a duração para 1439 se for 1440
          };
        }
        // Retorna os itens sem alteração se o 'dayOfWeek' não for o mesmo
        return item;
      });

      let params = {
        storeId: window.localStorage.getItem("merchantIdIfood") || "",
        shifts: shiftsAlterado,
      };

      // Verifica se o 'idOpenHour' foi encontrado no array
      const itemExists = openHoursLojaIfood.shifts.some((item: any) => item.id === idOpenHour);

      // Se o item não for encontrado, adiciona um novo item
      if (!itemExists) {
        shiftsAlterado.push({
          id: idOpenHour, // Usando o idOpenHour fornecido para o novo item
          dayOfWeek: dayOfWeekOpenHour,
          start: startOpenHour,
          duration: durationOpenHour, // Ajusta a duração para 1439 se for 1440
        });
      }

      requestService(apiIfoodPutMerchantsOpeningHours.url.replace("{id}", id.replace(/#/g, '%23')).replace("{merchantId}", merchantId), {
        method: apiIfoodPutMerchantsOpeningHours.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log("response", response);
          if (response.statusCode == 201 || response.statusCode == 200) {

            setTextToast("Horário alterado com sucesso. Aguarde o processamento.");
            setShowToast(true);
            setShowAlterarHorarioLojaIfood(false);

            setTimeout(() => {
              getMerchantsOpenHours();
            }, 3000);

          }
          else {
            setTextToast("Ocorreu um erro ao processar, verifique as informações e tente novamente.");
            setShowToast(true);
          }

        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    enviar();

  }

  function putMerchantsOpenHoursRemover(idRemover: any) {

    const enviar = async () => {
      console.log("aasasasasa", loja);
      let id = loja;
      let merchantId = window.localStorage.getItem("merchantIdIfood") || "";
      console.log("aasasasasa", merchantId);

      // Filtra o array para remover o item com id igual ao idOpenHour
      let shiftsAlterado = openHoursLojaIfood.shifts.filter((item: any) => item.id !== idRemover);

      // Monta o objeto params com o array alterado
      let params = {
        storeId: window.localStorage.getItem("merchantIdIfood") || "",
        shifts: shiftsAlterado,
      };

      console.log("shiftsAlterado", shiftsAlterado);


      requestService(apiIfoodPutMerchantsOpeningHours.url.replace("{id}", id.replace(/#/g, '%23')).replace("{merchantId}", merchantId), {
        method: apiIfoodPutMerchantsOpeningHours.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log("response", response);
          if (response.statusCode == 201 || response.statusCode == 200) {

            setTextToast("Horário removido com sucesso. Aguarde o processamento.");
            setShowToast(true);
            setShowAlterarHorarioLojaIfood(false);

            setTimeout(() => {
              getMerchantsOpenHours();
            }, 3000);

          }
          else {
            setTextToast("Ocorreu um erro ao processar, verifique as informações e tente novamente.");
            setShowToast(true);
          }

        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    enviar();

  }

  function deleteMerchantsInterruptions(interruptionId: any) {

    const enviar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;
      let merchantId = window.localStorage.getItem("merchantIdIfood") || "";
      console.log("aasasasasa", merchantId);

      let params = {
        time: selectedTime,
        description: selectedReason
      };

      requestService(apiIfoodDeleteMerchantsInterruptions.url.replace("{id}", id.replace(/#/g, '%23')).replace("{merchantId}", merchantId).replace("{interruptionId}", interruptionId), {
        method: apiIfoodDeleteMerchantsInterruptions.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response.data);

          if (response.statusCode == 204) {
            setTextToast("Interrupção excluída com sucesso. Aguarde o processamento.");
            setShowToast(true);

            setTimeout(() => {
              getMerchantsInterruptions();
            }, 3000);

          }
          else {
            setTextToast("Ocorreu um erro ao excluir, verifique as informações e tente novamente.");
            setShowToast(true);
          }



        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    enviar();

  }

  function postMerchantsInterruptions() {

    const enviar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;
      let merchantId = window.localStorage.getItem("merchantIdIfood") || "";
      console.log("aasasasasa", merchantId);

      let params = {
        time: selectedTime,
        description: selectedReason
      };

      requestService(apiIfoodPostMerchantsInterruptions.url.replace("{id}", id.replace(/#/g, '%23')).replace("{merchantId}", merchantId), {
        method: apiIfoodPostMerchantsInterruptions.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response.data);

          if (response.statusCode == 201 || response.statusCode == 200) {
            setTextToast("Interrupção realizada com sucesso. Aguarde o processamento.");
            setShowToast(true);
            setTimeout(() => {
              getMerchantsInterruptions();
            }, 3000);

            setShowFecharLojaIfood(false);
          }
          else {
            setTextToast("Ocorreu um erro ao processar, verifique as informações e tente novamente.");
            setShowToast(true);
          }

        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    if (!selectedTime) {
      setTextToast("Tempo é obrigatório para fechar a loja.");
      setShowToast(true);
    }
    else if (!selectedReason) {
      setTextToast("Motivo é obrigatório para fechar a loja.");
      setShowToast(true);
    }
    else {
      enviar();
    }

  }

  function postMerchantsInterruptionsPausa() {

    const enviar = async () => {
      // Verificar se todos os campos estão preenchidos
      if (!startDate || !startTime || !endDate || !endTime || !pauseTitle) {
        // Caso algum campo esteja vazio, exibir mensagem de erro
        setTextToast("Todos os campos são obrigatórios.");
        setShowToast(true);
        return; // Interromper o envio caso algum campo não esteja preenchido
      }

      console.log("aasasasasa", loja);
      let id = loja;
      let merchantId = window.localStorage.getItem("merchantIdIfood") || "";
      console.log("aasasasasa", merchantId);

      // Extração da data do início
      const extractedStartDate = new Date(startDate).toISOString().split("T")[0]; // "2024-12-04"
      // Combinar data e hora do início em um único formato ISO 8601 com fuso horário
      const startDateTime = `${extractedStartDate}T${startTime}`; // "2024-12-04T15:58:00-03:00"

      // Extração da data do término
      const extractedEndDate = new Date(endDate).toISOString().split("T")[0]; // "2024-12-04"
      // Combinar data e hora do término em um único formato ISO 8601 com fuso horário
      const endDateTime = `${extractedEndDate}T${endTime}`; // "2024-12-04T16:58:00-03:00"

      console.log("startDateTime", startDateTime);

      // Preencher o objeto params corretamente
      const params = {
        description: pauseTitle, // Título da pausa
        start: startDateTime,    // Início completo no formato ISO 8601
        end: endDateTime,        // Término completo no formato ISO 8601
      };

      // Realizar a requisição
      requestService(apiIfoodPostMerchantsInterruptions.url.replace("{id}", id.replace(/#/g, '%23')).replace("{merchantId}", merchantId), {
        method: apiIfoodPostMerchantsInterruptions.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response.data);

          if (response.statusCode == 201 || response.statusCode == 200) {
            setTextToast("Interrupção realizada com sucesso. Aguarde o processamento.");
            setShowToast(true);
            setTimeout(() => {
              getMerchantsInterruptions();
            }, 3000);

            setShowPausaProgramada(false);
          } else {
            setTextToast("Ocorreu um erro ao processar, verifique as informações e tente novamente.");
            setShowToast(true);
          }
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });
    };

    enviar();

  }

  async function closeModalModalBlueTooth() {

    setShowModalBlueTooth(false);
  }

  async function closeModalModalBlueToothSucess(deviceId: any) {

    setTextToast("Conectado ao dispositivo");
    setShowToast(true);

    // window.localStorage.setItem("impressoraBluetoothDeviceId", deviceId);

    setImpressoraBluetoothDeviceId(deviceId);
    setShowModalBlueTooth(false);
  }

  const generateTimeOptions2359 = () => {
    const times = [];
    let hour = 0;
    let minute = 0;

    // Gera opções de hora no formato HH:mm:ss, de 30 em 30 minutos
    for (let i = 0; i < 48; i++) {
      const hourStr = hour < 10 ? `0${hour}` : `${hour}`;
      const minuteStr = minute < 10 ? `0${minute}` : `${minute}`;
      times.push(`${hourStr}:${minuteStr}:00`);

      // Incrementa os minutos em 30
      minute += 30;
      if (minute === 60) {
        minute = 0;
        hour += 1;
      }
    }

    // Adiciona a última opção de horário como "23:59"
    times.push("23:59:00");

    return times;
  };

  const generateDurationOptions = () => {
    const durations = [];
    let duration = 30; // Começando com 30 minutos
    while (duration <= 1439) { // Limite máximo de 1439 minutos (23h59)
      durations.push(duration);
      duration += 30; // Incrementa de 30 em 30 minutos
    }

    // Adiciona explicitamente 23:59:00
    durations.push(1439); // 1439 minutos correspondem a 23:59

    return durations;
  };


  const formatDuration = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    // Se for 0 horas, retorna apenas os minutos
    if (hours === 0 && mins > 0) {
      return `${mins} minutos`;
    }

    // Se for 0 minutos, retorna apenas as horas
    if (mins === 0 && hours > 0) {
      return `${hours} horas`;
    }

    // Se ambos forem diferentes de 0, retorna as horas e minutos
    return `${hours} horas e ${mins} minutos`;
  };


  const handleDurationChange = (duration: number) => {
    console.log("duration", duration);
    setDurationOpenHour(duration);

    if (startOpenHour) {
      const [startHour, startMinute] = startOpenHour.split(':').map(Number);
      const startTotalMinutes = startHour * 60 + startMinute;

      const endTotalMinutes = startTotalMinutes + duration;

      // Verifica se o cálculo da duração gera um valor negativo
      console.log("startTotalMinutes", startTotalMinutes);
      console.log("endTotalMinutes", endTotalMinutes);
      // Verifica se o total de minutos ultrapassa 1440 minutos (24 horas)
      if (endTotalMinutes > 1439) {
        setDurationOpenHour(0);
        setTextToast("A duração não pode ultrapassar 23:59 horas. Verifique as informações e tente novamente.");
        setShowToast(true);
      }
    }
  };

  // Função para buscar impressoras
  const buscarImpressorasLocal = async () => {
    const url = "http://localhost:9100";

    try {
      const requestOptions: any = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
      };

      const resp = await fetch(url, requestOptions);
      const result = await resp.json(); // Supondo que o retorno é um JSON com uma lista de impressoras

      console.log("result", result);
      if (result && Array.isArray(result)) {
        setImpressorasLocal(result); // Define as impressoras no estado
      } else {
        console.error("Erro: Nenhuma resposta válida recebida.");
      }
    } catch (error) {
      console.error("Erro ao obter dados:", error);
    }
  };


  function converterParaString(dadosImpressao: any) {
    return String.fromCharCode(...dadosImpressao.filter((byte: any) => byte <= 0xFF)); // Filtrando valores válidos
  }

  async function enviarImpressao(printer: any, data: any) {

    const url = "http://localhost:9100/print";

    // Montando o corpo da requisição no formato necessário
    var raw = JSON.stringify({
      "printer": printer,
      "data": data,  // Dados no formato correto (com códigos ESC/POS)
    });

    try {
      // Configurações da requisição
      const requestOptions: any = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: 'follow',
      };

      // Envia a requisição utilizando fetch
      const resp = await fetch(url, requestOptions);

      // Aguardar a resposta como texto
      const result = await resp.text();

      if (result) {
        console.log("Impressão enviada com sucesso:", result);
      } else {
        console.error("Erro: Nenhuma resposta recebida.");
      }
    } catch (error) {
      console.error("Erro ao enviar dados para a impressora:", error);
    }
  }


  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">


          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack(); }}><IonIcon slot="icon-only" icon={arrowBack} /></IonButton>
          </IonButtons>


          <IonTitle slot="start">Configurações</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem lines="full">
            <IonLabel color="primary">Empresa</IonLabel>
          </IonItem>
          <IonItem lines="none">
            <IonLabel position="stacked">Nome</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={1}
              value={empresaNome}
              maxlength={100}
              placeholder="Nome da empresa"
              onIonInput={(e: any) => setEmpresaNome(e.target.value)}
            ></IonTextarea>
          </IonItem>
          <IonItem lines="none">
            <IonLabel position="stacked">Endereço</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={2}
              value={empresaEndereco}
              maxlength={200}
              placeholder="Endereço da empresa"
              onIonInput={(e: any) => setEmpresaEndereco(e.target.value)}
            ></IonTextarea>
            <div style={{ textAlign: 'right' }}>

            </div>
          </IonItem>
          <IonItem lines="none">
            <IonButton slot='end' size='large' onClick={() => { buscarLocalizacao(empresaEndereco); }}>Validar endereço</IonButton>
          </IonItem>
          <IonItem lines="none">

            <IonLabel position="stacked">Celular</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={1}
              value={empresaTelefone}
              maxlength={1300}
              placeholder="Telefone da empresa"
              onIonInput={(e: any) => setEmpresaTelefone(e.target.value)}
            ></IonTextarea>
          </IonItem>
          <IonItem lines="none">

            {/* Campos para localização */}
            <IonLabel position="stacked">Latitude</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={1}
              value={empresaLatitude}
              maxlength={20}
              placeholder="Latitude"
              onIonInput={(e: any) => setEmpresaLatitude(e.target.value)}
            ></IonTextarea>
          </IonItem>
          <IonItem lines="none">

            <IonLabel position="stacked">Longitude</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={1}
              value={empresaLongitude}
              maxlength={20}
              placeholder="Longitude"
              onIonInput={(e: any) => setEmpresaLongitude(e.target.value)}
            ></IonTextarea>
          </IonItem>

          <IonItem lines="none">

            <IonLabel position="stacked" style={{ marginBottom: '10px' }}>Logo</IonLabel>

            <IonButtons slot="end" onClick={async () => { await takePhoto(loja).catch((e) => { console.log("cancelado"); }); }}>
              <IonIcon color="tertiary" size='large' slot="icon-only" icon={addCircle}></IonIcon>
            </IonButtons>
            <img id="imagemLogo" src={urlLogo} style={{ height: '250px' }} alt="logo" />
          </IonItem>

          <IonItem lines="full">
            <IonLabel color="primary">Configuração impressora</IonLabel>
          </IonItem>

          <IonItem lines="none">
            <IonLabel position="stacked">Largura</IonLabel>
            <IonSelect
              value={impressoraLargura}
              placeholder="Selecione uma impressora"
              onIonChange={(e) => setImpressoraLargura(e.detail.value)}
            >
              <IonSelectOption key="largura80mm" value="80">
                80mm
              </IonSelectOption>
              <IonSelectOption key="largura58mm" value="58">
                58mm
              </IonSelectOption>
            </IonSelect>
          </IonItem>

          <IonItem lines="full" hidden={(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonLabel color="primary">Impressora local (Precisa do instalador de extensão da impressora)</IonLabel>
          </IonItem>

          <IonItem lines="none" hidden={(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonLabel>Habilitar</IonLabel>

            <IonToggle slot="end"
              checked={impressoraLocalHabilitar}
              onIonChange={(e: any) => {
                const isChecked = e.detail.checked;
                setImpressoraLocalHabilitar(isChecked);
                // window.localStorage.setItem("impressoraLocalHabilitar", isChecked ? "true" : "false");
              }}
            />
          </IonItem>


          <IonItem lines="none" hidden={(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonLabel position="stacked">Nome da impressora</IonLabel>
            <IonSelect
              value={impressoraLocalName}
              placeholder="Selecione uma impressora"
              onIonChange={(e) => setImpressoraLocalName(e.detail.value)}
            >
              {impressorasLocal?.map((impressora: any, index: any) => (
                <IonSelectOption key={index} value={impressora.Name}>
                  {impressora.Name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>

          {/* <IonItem lines="none" hidden={(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonButton size="large" slot="end" onClick={buscarImpressorasLocal}>Buscar impressoras</IonButton>
          </IonItem> */}


          <IonItem lines="none" hidden={(!isPlatform('mobileweb') && isPlatform('mobile'))} >

            <IonButton size="large" slot="end" onClick={async () => {
              if (!(!isPlatform('mobileweb') && isPlatform('mobile'))) {
                const comandosEscPos = gerarEscPos(impressoraLargura == "58" ? true : false);
                if (comandosEscPos) {
                  // Exemplo de uso
                  const resultadoString = converterParaString(comandosEscPos);
                  // const resultadoString = arrayToEscapedString(comandosEscPos);
                  //console.log(resultadoString);


                  enviarImpressao(impressoraLocalName, resultadoString);


                } else {
                  console.error('Erro ao gerar ESC/POS');
                }
              }
              else {
                setTextToast("Essa funcionalidade é somente para cmoputadores.");
                setShowToast(true);
              }
            }}>Testar conexão</IonButton>
          </IonItem>

          <IonItem lines="full" hidden={!(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonLabel color="primary">Impressora Bluetooth</IonLabel>
          </IonItem>

          <IonItem lines="none" hidden={!(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonLabel>Habilitar</IonLabel>

            <IonToggle slot="end"
              checked={impressoraBluetoothHabilitar}
              onIonChange={(e: any) => {
                const isChecked = e.detail.checked;
                setImpressoraBluetoothHabilitar(isChecked);
                // window.localStorage.setItem("impressoraBluetoothHabilitar", isChecked ? "true" : "false");
              }}
            />
          </IonItem>

          {/* <IonItem lines="none" hidden={!(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonLabel position="stacked">Device Id</IonLabel>
            <div style={{ background: "#eeeeee", borderRadius: "5px", padding: "10px", marginTop: "10px", width: "100%" }}>
              <IonInput
                type="text"
                value={impressoraBluetoothDeviceId}
                maxlength={100}
                placeholder="Device Id"
                onIonInput={(e: any) => setImpressoraBluetoothDeviceId(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>
          </IonItem> */}

          <IonItem lines="none" hidden={!(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonButton size="large" slot="end" onClick={() => {
              if (!isPlatform('mobileweb') && isPlatform('mobile')) {
                setShowModalBlueTooth(true);
              }
              else {
                setTextToast("Essa funcionalidade é somente para celulares e tablets.");
                setShowToast(true);
              }
            }}>Configurar</IonButton>

            {/* <IonButton size="large" slot="end" onClick={async () => {
              if (!isPlatform('mobileweb') && isPlatform('mobile')) {
                const services = await BleClient.getServices(impressoraBluetoothDeviceId);

                for (const service of services) {
                  console.log("Service UUID:", service.uuid);

                  for (const characteristic of service.characteristics) {
                    console.log("Characteristic UUID:", characteristic.uuid);
                  }
                }

                throw new Error("Nenhum UUID compatível encontrado");
              }
              else {
                setTextToast("Essa funcionalidade é somente para celulares e tablets.");
                setShowToast(true);
              }
            }}>Serviços</IonButton> */}

            <IonButton size="large" slot="end" onClick={async () => {
              if (!isPlatform('mobileweb') && isPlatform('mobile')) {
                const comandosEscPos = gerarEscPos(impressoraLargura == "58" ? true : false);
                if (comandosEscPos) {

                  const PRINT_SERVICE_UUID = "000018f0-0000-1000-8000-00805f9b34fb";
                  const PRINT_CHARACTERISTIC_UUID = "00002af1-0000-1000-8000-00805f9b34fb";
                  // Fragmentar os dados em pacotes menores
                  const chunkSize = 20; // Tamanho máximo permitido por pacote BLE
                  const buffer = comandosEscPos.buffer;

                  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

                  try {
                    // Loop para enviar os dados em pacotes
                    for (let i = 0; i < buffer.byteLength; i += chunkSize) {
                      const chunk = new DataView(buffer.slice(i, Math.min(i + chunkSize, buffer.byteLength)));
                      await BleClient.write(
                        impressoraBluetoothDeviceId || '',
                        PRINT_SERVICE_UUID,
                        PRINT_CHARACTERISTIC_UUID,
                        chunk
                      );
                      await delay(50); // Atraso de 50ms entre os envios
                    }

                    setTextToast("Impressão realizada com sucesso!");
                    setShowToast(true);
                  } catch (error) {
                    console.error("Erro durante a impressão:", error);
                    setTextToast("Erro ao realizar a impressão.");
                    setShowToast(true);
                  }

                } else {
                  console.error('Erro ao gerar ESC/POS');
                }
              }
              else {
                setTextToast("Essa funcionalidade é somente para celulares e tablets.");
                setShowToast(true);
              }
            }}>Testar conexão</IonButton>
          </IonItem>

          <IonItem lines="full" hidden={!(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonLabel color="primary">Impressora de rede</IonLabel>
          </IonItem>

          <IonItem lines="none" hidden={!(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonLabel>Habilitar</IonLabel>

            <IonToggle slot="end"
              checked={impressoraRedeHabilitar}
              onIonChange={(e: any) => setImpressoraRedeHabilitar(e.detail.checked)}
            />
          </IonItem>


          <IonItem lines="none" hidden={!(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonLabel position="stacked">IP</IonLabel>
            <div style={{ background: "#eeeeee", borderRadius: "5px", padding: "10px", marginTop: "10px", width: "100%" }}>
              <IonInput
                type="text"
                value={impressoraRedeIp}
                maxlength={15}
                placeholder="IP da impressora"
                onIonInput={(e: any) => setImpressoraRedeIp(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>
          </IonItem>

          <IonItem lines="none" hidden={!(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonLabel position="stacked">Porta</IonLabel>
            <div style={{ background: "#eeeeee", borderRadius: "5px", padding: "10px", marginTop: "10px", width: "100%" }}>
              <IonInput
                type="text"
                value={impressoraRedePorta}
                maxlength={15}
                placeholder="Porta da impressora"
                onIonInput={(e: any) => setImpressoraRedePorta(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>
          </IonItem>




          <IonItem lines="none" hidden={!(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonButton size="large" slot="end" onClick={() => {

              if (!isPlatform('mobileweb') && isPlatform('mobile')) {
                const ipImpressora = impressoraRedeIp;
                // const portaImpressora = impressoraRedePorta ? parseInt(impressoraRedePorta, 10) : 0impressoraRedePorta;

                const conectarEEnviar = () => {
                  // Cria o socket TCP
                  window.chrome.sockets.tcp.create({}, (createInfo) => {
                    const socketId = createInfo.socketId;

                    // Define o timeout para mostrar a mensagem de erro após 3 segundos
                    const timeoutId = setTimeout(() => {
                      setTextToast("Erro ao conectar.");
                      setShowToast(true);
                      window.chrome.sockets.tcp.close(socketId);
                    }, 3000); // 3 segundos

                    // Tenta conectar ao IP e porta especificados
                    window.chrome.sockets.tcp.connect(socketId, ipImpressora, parseInt(impressoraRedePorta, 10), (result) => {
                      if (result === 0) {
                        // Conexão bem-sucedida, cancela o timeout
                        clearTimeout(timeoutId);

                        console.log('Conexão bem-sucedida! Enviando dados...');

                        const comandosEscPos = gerarEscPos(impressoraLargura == "58" ? true : false);
                        if (comandosEscPos) {
                          window.chrome.sockets.tcp.send(socketId, comandosEscPos.buffer, (sendInfo) => {
                            console.log('Comando enviado com sucesso:', sendInfo);
                            window.chrome.sockets.tcp.close(socketId);
                          });
                        } else {
                          console.error('Erro ao gerar ESC/POS');
                        }
                      } else {
                        // Conexão falhou, cancela o timeout e mostra mensagem de erro
                        clearTimeout(timeoutId);
                        console.error(`Erro ao conectar à impressora. Verifique a conexão.`);
                        setTextToast("Erro ao conectar.");
                        setShowToast(true);
                        window.chrome.sockets.tcp.close(socketId);
                      }
                    });
                  });
                };


                // Chame a função com o URL do HTML que você deseja imprimir
                conectarEEnviar();
              }
              else {
                setTextToast("Essa funcionalidade é somente para celulares e tablets.");
                setShowToast(true);
              }


            }}>Testar conexão</IonButton>
          </IonItem>

          <IonItem lines="full">
            <IonLabel color="primary">Integração iFood</IonLabel>
          </IonItem>

          <IonItem lines="none" hidden={(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonLabel>Impressão automática de pedidos</IonLabel>

            <IonToggle slot="end"
              checked={impressaoAutomaticaIfood}
              onIonChange={(e: any) => {
                const isChecked = e.detail.checked;
                setImpressaoAutomaticaIfood(isChecked);
                // window.localStorage.setItem("impressoraLocalHabilitar", isChecked ? "true" : "false");
              }}
            />
          </IonItem>

          <IonItem lines="none">
            <IonButton size="large" slot="end" onClick={() => {
              getUserCode();
              setAtivarIfood1(true);
            }}>Ativar</IonButton>

            <IonButton size="large" slot="end" onClick={() => {
              getMerchants();
            }}>Detalhes</IonButton>
            {/* <IonButton size="large" slot="end" onClick={() => {
              try {
                console.log("getEvents loja", loja);
                const id = loja.replace(/#/g, '%23'); // Escapa o ID da loja

                requestService(
                  apiIfoodEvents.url.replace("{id}", id),
                  {
                    method: apiIfoodEvents.method,
                    headers: [["token", window.localStorage.getItem("token")]],
                    credentials: 'include',
                  },
                  history,
                  (response: any) => {
                    console.log(response);

                    if (response) {

                      Toast.show({
                        text: "Integrado com sucesso.",
                        position: "center",
                        duration: "long"
                      });

                    }
                  },
                  (error: any) => {
                    console.log(error);
                    if (error.message) {
                      Toast.show({
                        text: error.message,
                        position: "center",
                        duration: "long"
                      });
                    }
                  }
                );
              } catch (error) {
                console.error("Integração não foi realizada.", error);
              }
            }}>Validar integração</IonButton> */}
          </IonItem>

          {/* <IonItem lines="full">
            <IonLabel color="primary">Cadastro</IonLabel>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>Enviar mensagem para o Cliente</IonLabel>
            <IonToggle slot="end"
              checked={enviarMsgClienteCadastro.S === "sim"}
              onIonChange={(e: any) => setEnviarMsgClienteCadastro({ S: e.detail.checked ? "sim" : "não" })}
            />
          </IonItem> */}


          <IonItem lines="full">
            <IonLabel color="primary">Vendas</IonLabel>
          </IonItem>
          <IonItem lines="none">

            <IonLabel position="stacked">Condições Gerais</IonLabel>
            <IonTextarea

              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={6}
              value={condicoesGerais}
              maxlength={1500}
              placeholder="Condições gerais para apresentar no rodapé do orçamento"
              onIonInput={(e: any) => setCondicoesGerais(e.target.value)}
            ></IonTextarea>
          </IonItem>


          <IonItem lines="full">
            <IonLabel color="primary">{(window.localStorage.getItem("nomeInternoMesasOuComandas") || "Mesas")}</IonLabel>
          </IonItem>

          <IonItem lines="none">
            <IonLabel>Imprimir Parcial</IonLabel>

            <IonToggle slot="end"
              checked={imprimirMesaParcial}
              onIonChange={(e: any) => setImprimirMesaParcial(e.detail.checked)}
            />
          </IonItem>

          <IonItem lines="none">

            {/* Campos para serviços */}
            <IonLabel position="stacked">Nome Interno</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={1}
              value={nomeInternoMesaOuComanda}
              maxlength={50}
              placeholder="Nome Interno"
              onIonInput={(e: any) => setNomeInternoMesaOuComanda(e.target.value)}
            ></IonTextarea>
          </IonItem>
          <IonItem lines="none">

            <IonLabel position="stacked">Nome Interno no Plural</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={1}
              value={nomeInternoMesasOuComandas}
              maxlength={50}
              placeholder="Nome Interno no Plural"
              onIonInput={(e: any) => setNomeInternoMesasOuComandas(e.target.value)}
            ></IonTextarea>
          </IonItem>

          <IonItem lines="full">
            <IonLabel color="primary">Processo</IonLabel>
          </IonItem>
          <IonItem lines="none">

            {/* Campos para serviços */}
            <IonLabel position="stacked">Nome</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={1}
              value={servicoTipo}
              maxlength={50}
              placeholder="Nome do processo"
              onIonInput={(e: any) => setServicoTipo(e.target.value)}
            ></IonTextarea>
          </IonItem>
          <IonItem lines="none">

            <IonLabel position="stacked">Nome no Plural</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={1}
              value={servicoTipoPlural}
              maxlength={50}
              placeholder="Nome do processo no plural"
              onIonInput={(e: any) => setServicoTipoPlural(e.target.value)}
            ></IonTextarea>
          </IonItem>
          <IonItem lines="none">

            <IonLabel position="stacked">Nome do Workflow</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={1}
              value={nomeLinhaDoTempo}
              maxlength={50}
              placeholder="Nome do Workflow"
              onIonInput={(e: any) => setNomeLinhaDoTempo(e.target.value)}
            ></IonTextarea>

          </IonItem>
          <IonItem lines="none">

            <IonLabel position="stacked">Taxa</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={1}
              value={taxaServico}
              maxlength={50}
              placeholder="% - Taxa de Serviço"
              onIonInput={(e: any) => setTaxaServico(e.target.value)}
            ></IonTextarea>

          </IonItem>
          <IonItem lines="none">
            <IonLabel>Identificação por Número</IonLabel>

            <IonToggle slot="end"
              checked={identificacaoPorNumero}
              onIonChange={(e: any) => setIdentificacaoPorNumero(e.detail.checked)}
            />
          </IonItem>

          <IonItem lines="none">
            <IonLabel>Layout Simplificado (sem Workflow)</IonLabel>

            <IonToggle slot="end"
              checked={layoutSimplificado}
              onIonChange={(e: any) => setLayoutSimplificado(e.detail.checked)}
            />
          </IonItem>
          <IonItem lines="none">

            {/* Campos para os valores específicos */}
            <IonLabel>Zerar valores da venda ao criar o Processo</IonLabel>

            <IonToggle slot="end"
              checked={zerarValoresVenda}
              onIonChange={(e: any) => setZerarValoresVenda(e.detail.checked)}
            />

          </IonItem>

          <IonItem lines="none">
            <IonLabel>Esconder Valores</IonLabel>

            <IonToggle slot="end"
              checked={esconderValoresProjeto}
              onIonChange={(e: any) => setEsconderValoresProjeto(e.detail.checked)}
            />
          </IonItem>

          <IonItem lines="none">
            {/* Enviar mensagem para o Cliente */}
            <IonLabel>Enviar mensagem para o Cliente</IonLabel>
            <IonToggle slot="end"
              checked={enviarMsgClienteProjeto.S === "sim"}
              onIonChange={(e: any) => setEnviarMsgClienteProjeto({ S: e.detail.checked ? "sim" : "não" })}
            />
          </IonItem>

          <IonItem lines="none" hidden={(window.localStorage.getItem("servicoPrincipal") != "Obras")}>
            {/* Enviar mensagem ao responsável */}
            <IonLabel>Enviar mensagem para o Arquiteto</IonLabel>
            <IonToggle slot="end"
              checked={enviarMsgResposavelProjeto.S === "sim"}
              onIonChange={(e: any) => setEnviarMsgResposavelProjeto({ S: e.detail.checked ? "sim" : "não" })}
            />
          </IonItem>


          <IonItem lines="full">
            <IonLabel color="primary">Agenda</IonLabel>
          </IonItem>

          <IonItem lines="none">
            <IonLabel>Tipo de Agendamento Padrão</IonLabel>
            <IonSelect placeholder="Selecione" slot="end" value={tipoAgendamentoPadrao} onIonChange={(e) => { setTipoAgendamentoPadrao(e.detail.value || "") }}>
              <IonSelectOption value="entrega">entrega</IonSelectOption>
              <IonSelectOption value="serviço">serviço</IonSelectOption>
              <IonSelectOption value="reunião">reunião</IonSelectOption>
              <IonSelectOption value="consulta">consulta</IonSelectOption>
              <IonSelectOption value="visita técnica">visita técnica</IonSelectOption>
              <IonSelectOption value="reserva">reserva</IonSelectOption>
              <IonSelectOption value="check in">check in</IonSelectOption>
              <IonSelectOption value="check out">check out</IonSelectOption>
            </IonSelect>

          </IonItem>
          <IonItem lines="none">
            <IonLabel>Habilitar Período</IonLabel>

            <IonToggle slot="end"
              checked={servicoPeriodoHoraEmpresa}
              onIonChange={(e: any) => setServicoPeriodoHoraEmpresa(e.detail.checked)}
            />
          </IonItem>
          <IonItem lines="none">
            <IonLabel>Mostrar horário (sim) / período (não)</IonLabel>
            <IonToggle slot="end"
              checked={parametroMostrarHorario}
              onIonChange={(e: any) => setParametroMostrarHorario(e.detail.checked)}
            />
          </IonItem>

          <IonItem lines="none">
            <IonLabel>Endereço empresa (sim) / cliente (não)</IonLabel>
            <IonToggle slot="end"
              checked={enderecoPrincipalEmpresa}
              onIonChange={(e: any) => setEnderecoPrincipalEmpresa(e.detail.checked)}
            />

          </IonItem>
          <IonItem lines="none">
            <IonLabel>Enviar Google Agenda Cliente</IonLabel>
            <IonToggle slot="end"
              checked={enviarGoogleAgendaCliente}
              onIonChange={(e: any) => setEnviarGoogleAgendaCliente(e.detail.checked)}
            />
          </IonItem>
          <IonItem lines="none" hidden={(window.localStorage.getItem("servicoPrincipal") != "Obras")}>
            <IonLabel>Enviar Google Agenda Arquiteto</IonLabel>
            <IonToggle slot="end"
              checked={enviarGoogleAgendaResponsavel}
              onIonChange={(e: any) => setEnviarGoogleAgendaResponsavel(e.detail.checked)}
            />
          </IonItem>

          <IonItem lines="none">
            {/* Enviar mensagem para o Cliente */}
            <IonLabel>Enviar mensagem para o Cliente</IonLabel>
            <IonToggle slot="end"
              checked={enviarMsgClienteAgendamento.S === "sim"}
              onIonChange={(e: any) => setEnviarMsgClienteAgendamento({ S: e.detail.checked ? "sim" : "não" })}
            />
          </IonItem>

          <IonItem lines="none" hidden={(window.localStorage.getItem("servicoPrincipal") != "Obras")}>
            {/* Enviar mensagem ao responsável */}
            <IonLabel>Enviar mensagem para o Arquiteto</IonLabel>
            <IonToggle slot="end"
              checked={enviarMsgResposavelAgendamento.S === "sim"}
              onIonChange={(e: any) => setEnviarMsgResposavelAgendamento({ S: e.detail.checked ? "sim" : "não" })}
            />
          </IonItem>



          <IonItem lines="full">
            <IonLabel color="primary">Operação</IonLabel>
          </IonItem>

          <IonItem lines="none">
            {/* Enviar mensagem para o Cliente */}
            <IonLabel>Enviar mensagem para o Cliente</IonLabel>
            <IonToggle slot="end"
              checked={enviarMsgClienteOperacao.S === "sim"}
              onIonChange={(e: any) => setEnviarMsgClienteOperacao({ S: e.detail.checked ? "sim" : "não" })}
            />
          </IonItem>


          <IonItem lines="none" hidden={(window.localStorage.getItem("servicoPrincipal") != "Obras")}>
            {/* Enviar mensagem ao responsável */}
            <IonLabel>Enviar mensagem para o Arquiteto</IonLabel>
            <IonToggle slot="end"
              checked={enviarMsgResposavelOperacao.S === "sim"}
              onIonChange={(e: any) => setEnviarMsgResposavelOperacao({ S: e.detail.checked ? "sim" : "não" })}
            />
          </IonItem>


















        </IonList>
        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonAlert isOpen={showToast2}
          onDidDismiss={() => setShowToast2(false)}
          header={'Alteração de cadastro'}
          message={'Você <strong>confirma</strong> a alteração?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {

                setShowLoading(true);

                window.localStorage.setItem("impressoraLocalHabilitar", impressoraLocalHabilitar ? "true" : "false");
                window.localStorage.setItem("impressoraLocalName", impressoraLocalName);

                window.localStorage.setItem("impressoraBluetoothHabilitar", impressoraBluetoothHabilitar ? "true" : "false");
                window.localStorage.setItem("impressoraBluetoothDeviceId", impressoraBluetoothDeviceId);

                const objectRequest = {
                  empresaNome,
                  empresaEndereco,
                  empresaTelefone,
                  empresaLatitude,
                  empresaLongitude,
                  urlLogo,
                  condicoesGerais,
                  impressoraLargura,
                  impressoraRedeHabilitar,
                  impressoraRedeIp,
                  impressoraRedePorta: impressoraRedePorta ? parseInt(impressoraRedePorta, 10) : 0,
                  // impressoraBluetoothHabilitar,
                  // impressoraBluetoothDeviceId,
                  impressaoAutomaticaIfood,
                  nomeInternoMesaOuComanda,
                  nomeInternoMesasOuComandas,
                  imprimirMesaParcial,
                  servicoTipo,
                  servicoTipoPlural,
                  nomeLinhaDoTempo,
                  taxaServico,
                  identificacaoPorNumero,
                  layoutSimplificado,
                  zerarValoresVenda,
                  esconderValoresProjeto,
                  tipoAgendamentoPadrao,
                  servicoPeriodoHoraEmpresa,
                  parametroMostrarHorario,
                  enderecoPrincipalEmpresa,
                  enviarGoogleAgendaCliente,
                  enviarGoogleAgendaResponsavel,
                  instrucoesMotorista,
                  enviarMsgClienteCadastro: enviarMsgClienteCadastro.S,
                  enviarMsgClienteProjeto: enviarMsgClienteProjeto.S,
                  enviarMsgResposavelProjeto: enviarMsgResposavelProjeto.S,
                  enviarMsgClienteAgendamento: enviarMsgClienteAgendamento.S,
                  enviarMsgResposavelAgendamento: enviarMsgResposavelAgendamento.S,
                  enviarMsgResposavelOperacao: enviarMsgResposavelOperacao.S,
                  enviarMsgClienteOperacao: enviarMsgClienteOperacao.S
                };
                let id = loja;

                console.log("id", id)

                requestService(
                  empresaAlterar.url.replace("{id}", id.replaceAll("#", "%23")).concat("?tipo=configuracoesempresa"),
                  {
                    method: empresaAlterar.method,
                    body: JSON.stringify(objectRequest),
                    headers: [
                      ["token", window.localStorage.getItem("token")]
                    ],
                    credentials: 'include'
                  },
                  history,
                  (response: any) => {
                    console.log(response);
                    if (response) {
                      setTextToast("Alteração realizado com sucesso.");
                      setShowSucesso(true);

                    } else if (response.status === 400) {
                      console.log(response);
                      setTextToast("Ocorreu um erro ao efetuar a alteração.");
                      setShowToast(true);

                    }
                    setShowLoading(false);
                  },
                  (error: any) => {
                    console.log(error);
                    setTextToast("Ocorreu um erro ao efetuar a alteração.");
                    setShowToast(true);
                    setShowLoading(false);
                  })
              }
            }
          ]}
        />

        <IonModal isOpen={(Array.isArray(listaEnderecos) && listaEnderecos.length > 0)} onDidDismiss={() => { setListaEnderecos([]); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Selecione um endereço</IonTitle>
              <IonButtons slot="start" onClick={() => setListaEnderecos([])}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonList>
              {readListaEnderecos()}
            </IonList>
          </IonContent>
          <IonFooter>
            <IonButton expand="block" color="primary" onClick={(e) => {

              mostartAlerta({
                backdropDismiss: false,
                header: 'Número e complemento',
                message: 'Adicione Número e complemento',
                inputs: [
                  {
                    name: 'mensagem',
                    type: 'textarea',
                    id: 'name2-id',
                    placeholder: 'Digite aqui o número e complemento',
                  }],
                buttons: ['Cancel', {
                  text: 'OK', handler: (d) => {
                    setEmpresaEndereco(localEnderecoSelecionado + ", " + d.mensagem);
                    buscarLocalizacao(localEnderecoSelecionado + ", " + d.mensagem);
                  }
                }],
                onDidDismiss: (e) => { console.log('did dismiss'); }
              })

              setListaEnderecos([]);
            }}>Avançar</IonButton>
          </IonFooter>
        </IonModal>


        <IonModal isOpen={ativarIfood1} onDidDismiss={() => { setAtivarIfood1(false); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Integração iFood</IonTitle>
              <IonButtons slot="start" onClick={() => setAtivarIfood1(false)}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonList style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "16px" }}>
              <IonItem lines="none" style={{ justifyContent: "center" }}>
                <img style={{ width: '120px', borderRadius: '5px' }} src={iconIfood} alt="iFood icon" />
              </IonItem>

              <IonItem lines="none" style={{ justifyContent: "center", textAlign: "center" }}>
                <div>
                  Ao integrar, os pedidos do iFood irão aparecer em {window.localStorage.getItem("servicoTipoPlural") || "Projetos"}
                </div>
              </IonItem>

              <IonItem lines="none" style={{ flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                <div>
                  Copie o código abaixo e cole no portal do parceiro:
                </div>




              </IonItem>

              <IonItem lines="none" style={{ flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                <div>


                  <IonInput
                    style={{
                      textAlign: "center",
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "#7D7D7D",
                      border: "1px solid #E0E0E0",
                      borderRadius: "8px",
                      padding: "12px"
                    }}
                    value={userCode}
                    readonly
                  />

                  <IonButton fill="clear" onClick={() => {
                    navigator.clipboard.writeText(userCode);
                    setTextToast("Código copiado com sucesso.");
                    setShowToast(true);
                  }}>
                    <IonIcon color="primary" icon={copy} /> copiar
                  </IonButton>
                </div>
              </IonItem>
              <IonItem lines="none" style={{ textAlign: "center", justifyContent: "center" }}>
                <div>
                  Ou clique em: <a href={`https://portal.ifood.com.br/apps/code?c=${userCode}`} target="_blank" rel="noopener noreferrer">https://portal.ifood.com.br/apps/code?c={userCode}</a>
                </div>
              </IonItem>
            </IonList>

          </IonContent>
          <IonFooter>
            <IonButton expand="block" color="primary" onClick={(e) => {
              setAtivarIfood1(false);
              setAtivarIfood2(true);
            }}>Avançar</IonButton>
          </IonFooter>
        </IonModal>

        <IonModal isOpen={ativarIfood2} onDidDismiss={() => { setAtivarIfood2(false); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Integração iFood</IonTitle>
              <IonButtons slot="start" onClick={() => setAtivarIfood2(false)}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonList style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "16px" }}>
              <IonItem lines="none" style={{ justifyContent: "center" }}>
                <img style={{ width: '200px', borderRadius: '5px' }} src={iconIfood} alt="iFood icon" />
              </IonItem>

              <IonItem lines="none" style={{ justifyContent: "center", textAlign: "center" }}>
                <div>
                  Agora, copie o código no portal do parceiro gerado do iFood e cole abaixo:
                </div>
              </IonItem>

              <IonItem lines="none" style={{ justifyContent: "center", width: "100%", maxWidth: "300px" }}>
                <IonInput
                  style={{
                    textAlign: "center",
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#7D7D7D",
                    border: "1px solid #E0E0E0",
                    borderRadius: "8px",
                    padding: "12px"
                  }}
                  value={authorizationCode}
                  onIonInput={(e: any) => setAuthorizationCode(e.target.value)}
                  placeholder='AAAA-AAAA'
                />

              </IonItem>

            </IonList>

          </IonContent>
          <IonFooter>
            <IonButton expand="block" color="primary" onClick={(e) => {

              getTokenIfood();

            }}>Integrar</IonButton>
          </IonFooter>
        </IonModal>


        <IonModal isOpen={showLojasIfood} onDidDismiss={() => { setShowLojasIfood(false); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Integração iFood</IonTitle>
              <IonButtons slot="start" onClick={() => setShowLojasIfood(false)}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonList>
              {readListalojasIfood()}
            </IonList>
          </IonContent>

          {/* <IonFooter>
            <IonButton expand="block" color="primary" onClick={(e) => {
              setShowLojasIfood(false);
            }}>Remover integrações</IonButton>
          </IonFooter> */}
        </IonModal>

        <IonModal isOpen={showDetalheLojaIfood} onDidDismiss={() => { setShowDetalheLojaIfood(false); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Detalhes - Integração iFood</IonTitle>
              <IonButtons slot="start" onClick={() => setShowDetalheLojaIfood(false)}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            {detalheLojaIfood && (
              <>
                <IonCard>
                  {/* <IonCardHeader>
                    <IonCardTitle>{detalheLojaIfood.name}</IonCardTitle>
                    <IonCardSubtitle>{detalheLojaIfood.corporateName}</IonCardSubtitle>
                  </IonCardHeader> */}
                  <IonCardContent>
                    <IonList>
                      <IonItem lines="none">
                        <IonLabel>
                          <h3>{detalheLojaIfood.name}</h3>
                          <p>{detalheLojaIfood.corporateName}</p>

                        </IonLabel>
                      </IonItem>

                      <IonItem lines="none">
                        <IonLabel>
                          <h3>Endereço</h3>
                          <p>{detalheLojaIfood.address.street}, {detalheLojaIfood.address.number}</p>
                          <p>{detalheLojaIfood.address.city}, {detalheLojaIfood.address.state} - {detalheLojaIfood.address.country}</p>
                          <p>CEP: {detalheLojaIfood.address.postalCode}</p>
                        </IonLabel>
                      </IonItem>
                      <IonItem lines="none">
                        <IonLabel>
                          <h3>Latitude / Longitude</h3>
                          <p>{detalheLojaIfood.address.latitude}, {detalheLojaIfood.address.longitude}</p>
                        </IonLabel>
                      </IonItem>
                      <IonItem lines="none">
                        <IonLabel>
                          <h3>Ticket Médio</h3>
                          <p>R$ {detalheLojaIfood.averageTicket.toFixed(2)}</p>
                        </IonLabel>
                      </IonItem>
                      <IonItem lines="none">
                        <IonLabel>
                          <h3>Status</h3>
                          <p>{detalheLojaIfood.status}</p>
                        </IonLabel>
                      </IonItem>
                      <IonItem lines="none">
                        <IonLabel>
                          <h3>Operações</h3>
                          {detalheLojaIfood.operations?.map((op: any, index: any) => (
                            <p key={index}>{op.name}</p>
                          ))}
                        </IonLabel>
                      </IonItem>
                    </IonList>
                  </IonCardContent>
                </IonCard>
              </>
            )}
          </IonContent>

          {/* <IonFooter>
            <IonButton expand="block" color="primary" onClick={() => setShowLojasIfood(false)}>
              Desativar integração
            </IonButton>
          </IonFooter> */}
        </IonModal>


        <IonModal isOpen={showStatusLojaIfood} onDidDismiss={() => { setShowStatusLojaIfood(false); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Status - Integração iFood</IonTitle>
              <IonButtons slot="start" onClick={() => setShowStatusLojaIfood(false)}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            {statusLojaIfood && Array.isArray(statusLojaIfood) && statusLojaIfood?.map((item: any, index: any) => (
              <IonCard key={index}>
                {/* <IonCardHeader>
                  <IonCardTitle>{item.message?.title}</IonCardTitle>
                  <IonCardSubtitle>{item.message?.subtitle}</IonCardSubtitle>
                </IonCardHeader> */}
                <IonCardContent>
                  <IonList>
                    {/* <IonItem>
                      <IonLabel>
                        <h3>Operação</h3>
                        <p>{item.operation || "Não especificado"}</p>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel>
                        <h3>Canal de Venda</h3>
                        <p>{item.salesChannel || "Não especificado"}</p>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel>
                        <h3>Disponível</h3>
                        <p>{item.available ? "Sim" : "Não"}</p>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel>
                        <h3>Estado</h3>
                        <p>{item.state || "Não especificado"}</p>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel>
                        <h3>Reabertura Permitida</h3>
                        <p>{item.reopenable?.reopenable ? "Sim" : "Não"}</p>
                      </IonLabel>
                    </IonItem> */}

                    <IonItem lines="none">
                      <IonLabel>
                        <h3>{item.message?.title}</h3>
                        <p>{item.message?.subtitle}</p>
                      </IonLabel>
                    </IonItem>
                    {item.validations?.length > 0 && (
                      <>
                        {
                          item.validations.map((validation: any, idx: any) => (
                            <div key={idx}>
                              <IonItem lines="none">
                                <IonLabel>
                                  <h3>{validation.message?.title}</h3>
                                  <p>{validation.message?.subtitle}</p>
                                </IonLabel>
                              </IonItem>


                            </div>
                          ))
                        }
                      </>
                    )}
                  </IonList>
                </IonCardContent>
              </IonCard>
            ))}
          </IonContent>

          {/* <IonFooter>
            <IonButton expand="block" color="primary" onClick={() => setShowFecharLojaIfood(true)}>
              Fechar Loja
            </IonButton>
          </IonFooter> */}
        </IonModal>

        <IonModal isOpen={showInterruptionsLojaIfood} onDidDismiss={() => { setShowInterruptionsLojaIfood(false); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Interrupções - Integração iFood</IonTitle>
              <IonButtons slot="start" onClick={() => setShowInterruptionsLojaIfood(false)}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            {/* <IonFab vertical="top" horizontal="end" slot="fixed">
              <IonFabButton color="tertiary" onClick={() => { getMerchantsInterruptions(); }} >
                <IonIcon color="light" icon={refresh}></IonIcon>
              </IonFabButton>
            </IonFab> */}
            {interruptionsLojaIfood && Array.isArray(interruptionsLojaIfood) && interruptionsLojaIfood.map((item: any, index: any) => (
              <IonCard key={index}>
                <IonCardContent>
                  <IonList>
                    <IonItem lines="none">
                      <IonLabel>
                        <h3>{item.description}</h3>
                        <p>
                          Início: {new Date(item.start).toLocaleDateString("pt-BR") + " " +
                            new Date(item.start).toLocaleTimeString("pt-BR", { hour12: false })}
                        </p>
                        <p>
                          Fim: {new Date(item.end).toLocaleDateString("pt-BR") + " " +
                            new Date(item.end).toLocaleTimeString("pt-BR", { hour12: false })}
                        </p>
                      </IonLabel>
                      <IonButton
                        fill="clear"
                        slot="end"
                        color="danger"
                        onClick={() => deleteMerchantsInterruptions(item.id)}
                      >
                        <IonIcon icon={trashOutline} />
                      </IonButton>
                    </IonItem>
                  </IonList>
                </IonCardContent>
              </IonCard>
            ))}
          </IonContent>

          <IonFooter>
            <IonButton expand="block" color="primary" onClick={() => {
              setStartDate("");  // Limpa o campo de data de início
              setStartTime("");  // Limpa o campo de hora de início
              setEndDate("");    // Limpa o campo de data de término
              setEndTime("");    // Limpa o campo de hora de término

              // Limpar o título da pausa
              setPauseTitle(""); // Limpa o título da pausa
              setShowPausaProgramada(true);
            }}>
              Pausa Programada
            </IonButton>
            <IonButton expand="block" color="primary" onClick={() => {
              setSelectedTime("");
              setSelectedReason("");
              setShowFecharLojaIfood(true);
            }}>
              Fechar Loja
            </IonButton>
          </IonFooter>


        </IonModal>

        <IonModal isOpen={showOpenHoursLojaIfood} onDidDismiss={() => { setShowOpenHoursLojaIfood(false); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Horários - Integração iFood</IonTitle>
              <IonButtons slot="start" onClick={() => setShowOpenHoursLojaIfood(false)}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            {/* <IonFab vertical="top" horizontal="end" slot="fixed">
              <IonFabButton color="tertiary" onClick={() => { getMerchantsOpenHours(); }} >
                <IonIcon color="light" icon={setTime}></IonIcon>
              </IonFabButton>
            </IonFab> */}
            {openHoursLojaIfood.shifts && Array.isArray(openHoursLojaIfood.shifts) && openHoursLojaIfood.shifts.map((item: any, index: any) => {
              // Função para converter duração (em minutos) para horas e minutos
              const formatDuration = (minutes: number) => {
                const hours = Math.floor(minutes / 60);
                const mins = minutes % 60;
                return `${hours} horas e ${mins} minutos`;
              };

              // Mapeamento dos dias da semana para português
              const daysOfWeekMap: { [key: string]: string } = {
                "MONDAY": "Segunda-feira",
                "TUESDAY": "Terça-feira",
                "WEDNESDAY": "Quarta-feira",
                "THURSDAY": "Quinta-feira",
                "FRIDAY": "Sexta-feira",
                "SATURDAY": "Sábado",
                "SUNDAY": "Domingo"
              };

              // Traduz o dia da semana
              const translatedDay = daysOfWeekMap[item.dayOfWeek] || item.dayOfWeek;

              return (
                <IonCard key={index}>
                  <IonCardContent>
                    <IonList>
                      <IonItem lines="none">
                        <IonLabel>
                          <h3>{translatedDay}</h3> {/* Usa o dia traduzido */}
                          <p>Começa: {item.start}</p>
                          <p>Duração: {formatDuration(item.duration)}</p> {/* Converte a duração */}
                        </IonLabel>
                        <IonButton
                          fill="clear"
                          slot="end"
                          color="primary"
                          onClick={() => {
                            setIdOpenHour(item.id);
                            setDayOfWeekOpenHour(item.dayOfWeek);
                            setStartOpenHour(item.start);
                            setDurationOpenHour(item.duration);
                            setShowAlterarHorarioLojaIfood(true);
                          }}
                        >
                          <IonIcon icon={pencilOutline} />
                        </IonButton>
                        <IonButton
                          fill="clear"
                          slot="end"
                          color="danger"
                          onClick={() => {

                            putMerchantsOpenHoursRemover(item.id);

                          }}
                        >
                          <IonIcon icon={trashOutline} />
                        </IonButton>
                      </IonItem>
                    </IonList>
                  </IonCardContent>
                </IonCard>
              );
            })}

          </IonContent>

          <IonFooter>
            <IonButton expand="block" color="primary" onClick={() => {
              setIdOpenHour("");
              setDayOfWeekOpenHour("");
              setStartOpenHour("");
              setDurationOpenHour("");
              setShowAlterarHorarioLojaIfood(true);
            }
            }>
              Adicionar horário
            </IonButton>
          </IonFooter>

        </IonModal>

        <IonModal isOpen={showAlterarHorarioLojaIfood} onDidDismiss={() => setShowAlterarHorarioLojaIfood(false)}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Alterar Horário Loja - Integração iFood</IonTitle>
              <IonButtons slot="start">
                <IonButton onClick={() => setShowAlterarHorarioLojaIfood(false)}>
                  <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent className="ion-padding">
            <IonList>
              <IonItem>
                <IonLabel position="stacked">Dia da Semana</IonLabel>
                <IonSelect
                  value={dayOfWeekOpenHour}
                  placeholder="Selecione um dia"
                  onIonChange={(e) => setDayOfWeekOpenHour(e.detail.value)}
                >
                  <IonSelectOption value="MONDAY">Segunda-feira</IonSelectOption>
                  <IonSelectOption value="TUESDAY">Terça-feira</IonSelectOption>
                  <IonSelectOption value="WEDNESDAY">Quarta-feira</IonSelectOption>
                  <IonSelectOption value="THURSDAY">Quinta-feira</IonSelectOption>
                  <IonSelectOption value="FRIDAY">Sexta-feira</IonSelectOption>
                  <IonSelectOption value="SATURDAY">Sábado</IonSelectOption>
                  <IonSelectOption value="SUNDAY">Domingo</IonSelectOption>
                </IonSelect>
              </IonItem>


              <IonItem>
                <IonLabel position="stacked">Hora de Início</IonLabel>
                <IonSelect
                  value={startOpenHour}
                  placeholder="Selecione um horário"
                  onIonChange={(e) => setStartOpenHour(e.detail.value)}
                >
                  {generateTimeOptions2359().map((time: any, index: any) => (
                    <IonSelectOption key={index} value={time}>
                      {time}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">Duração</IonLabel>
                <IonSelect
                  value={durationOpenHour}
                  placeholder="Selecione a duração"
                  onIonChange={(e) => handleDurationChange(e.detail.value)}
                >
                  {generateDurationOptions().map((duration: any, index: any) => (
                    <IonSelectOption key={index} value={duration}>
                      {formatDuration(duration)}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>


            </IonList>
          </IonContent>

          <IonFooter>
            <IonButton expand="block" color="primary" onClick={() => putMerchantsOpenHours()}>
              Salvar
            </IonButton>
          </IonFooter>
        </IonModal>

        <IonModal isOpen={showFecharLojaIfood} onDidDismiss={() => setShowFecharLojaIfood(false)}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Fechar Loja - Integração iFood</IonTitle>
              <IonButtons slot="start">
                <IonButton onClick={() => setShowFecharLojaIfood(false)}>
                  <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent className="ion-padding">
            <div>
              <h2>Informe o motivo do fechamento.</h2>

              <IonList>
                <IonItem lines="none">
                  <IonLabel>Fechar por quanto tempo?</IonLabel>
                </IonItem>
                <IonRadioGroup value={selectedTime} onIonChange={(e) => setSelectedTime(e.detail.value)}>
                  <IonItem lines="none">
                    <IonLabel>15 min</IonLabel>
                    <IonRadio slot="start" value="15m" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>30 min</IonLabel>
                    <IonRadio slot="start" value="30m" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>1 h</IonLabel>
                    <IonRadio slot="start" value="1h" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>3 h</IonLabel>
                    <IonRadio slot="start" value="3h" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>6 h</IonLabel>
                    <IonRadio slot="start" value="6h" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>12 h</IonLabel>
                    <IonRadio slot="start" value="12h" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>24 h</IonLabel>
                    <IonRadio slot="start" value="24h" />
                  </IonItem>
                </IonRadioGroup>
              </IonList>
              <IonList>
                <IonItem lines="none">
                  <IonLabel>Qual o motivo?</IonLabel>
                </IonItem>
                <IonRadioGroup value={selectedReason} onIonChange={(e) => setSelectedReason(e.detail.value)}>
                  <IonItem lines="none">
                    <IonLabel>Muitos pedidos</IonLabel>
                    <IonRadio slot="start" value="Muitos pedidos" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>Manutenção/Problemas Técnicos</IonLabel>
                    <IonRadio slot="start" value="Manutenção/Problemas Técnicos" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>Horário de Funcionamento/Feriado</IonLabel>
                    <IonRadio slot="start" value="Horário de Funcionamento/Feriado" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>Falta de Equipe/Problemas Pessoais</IonLabel>
                    <IonRadio slot="start" value="Falta de Equipe/Problemas Pessoais" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>Problemas de Logística</IonLabel>
                    <IonRadio slot="start" value="Problemas de Logística" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>Outros</IonLabel>
                    <IonRadio slot="start" value="Outros" />
                  </IonItem>
                </IonRadioGroup>
              </IonList>
            </div>
          </IonContent>

          <IonFooter>
            <IonButton expand="block" color="primary" onClick={() => postMerchantsInterruptions()}>
              Fechar Loja
            </IonButton>
          </IonFooter>
        </IonModal>

        <IonModal isOpen={showPausaProgramada} onDidDismiss={() => setShowPausaProgramada(false)}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Criar Pausa Programada</IonTitle>
              <IonButtons slot="start">
                <IonButton onClick={() => setShowPausaProgramada(false)}>
                  <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent className="ion-padding">
            <div>
              <h3>A loja ficará indisponível nesta data, mesmo que esteja dentro do horário de funcionamento e o Gestor de Pedidos estiver aberto.</h3>

              {/* Título da pausa programada */}
              <IonItem>
                <IonLabel position="stacked">Título da Pausa Programada</IonLabel>
                <IonInput placeholder="Digite o título" onIonChange={(e) => setPauseTitle(e.detail.value!)} />
              </IonItem>

              {/* Começa em */}
              <div>
                <IonItem>
                  <IonLabel position="stacked" style={{ paddingBottom: "14px" }}>Começa em</IonLabel>
                  <IonDatetime
                    presentation="date"
                    onIonChange={(e) => setStartDate(e.detail.value!)}
                  />
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked" style={{ paddingBottom: "14px" }}>Hora de início</IonLabel>
                  <IonSelect
                    value={startTime}
                    placeholder="Selecione um horário"
                    onIonChange={(e) => setStartTime(e.detail.value)}
                  >
                    {generateTimeOptions2359().map((time: any, index: any) => (
                      <IonSelectOption key={index} value={time}>
                        {time}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>

                {/* Termina em */}
                <IonItem>
                  <IonLabel position="stacked" style={{ paddingBottom: "14px" }}>Termina em</IonLabel>
                  <IonDatetime
                    presentation="date"
                    onIonChange={(e) => setEndDate(e.detail.value!)}
                  />
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked" style={{ paddingBottom: "14px" }}>Hora de término</IonLabel>
                  <IonSelect
                    value={endTime}
                    placeholder="Selecione um horário"
                    onIonChange={(e) => setEndTime(e.detail.value)}
                  >
                    {generateTimeOptions2359().map((time: any, index: any) => (
                      <IonSelectOption key={index} value={time}>
                        {time}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </div>
            </div>
          </IonContent>

          <IonFooter>
            <IonButton expand="block" color="primary" onClick={() => postMerchantsInterruptionsPausa()}>
              Salvar pausa programada
            </IonButton>
          </IonFooter>
        </IonModal>



        <IonModal isOpen={showModalBlueTooth} onDidDismiss={() => { setShowModalBlueTooth(false); }}>
          <Bluetooth closeAction={closeModalModalBlueToothSucess} closeActionSimple={closeModalModalBlueTooth}></Bluetooth>
        </IonModal>

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showSucesso}
          onDidDismiss={(e: any) => { setShowSucesso(false); history.goBack(); }}
          message={'Alterações salvas com sucesso!'}
          duration={3000}
          animated={false}
        />
      </IonContent >
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonButton onClick={() => cadastrar()} expand="block" type="submit" hidden={acessos?.configuracoes != 'edicao'}>Salvar</IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage >
  );
};

export default ContaAlt;
